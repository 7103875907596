import {
  IAction,
  IInitialState,
  IOfficeVisitCrm,
} from '@shared-components/models';
import * as actionType from './office-visit.constant';

export const OFFICE_VISIT_FEATURE_KEY = 'office_visits';

export const initialOfficeVisitstate: IInitialState<IOfficeVisitCrm> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
  drawerIsOpen: false,
  detailDrawerIsOpen: false,
  refresh: false,
};

const officeVisitReducer = (
  state = initialOfficeVisitstate,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionType.ADD_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.ADD_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.GET_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleData: action.payload,
      };
    case actionType.GET_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.UPDATE_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionType.UPDATE_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
      };
    case actionType.UPDATE_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actionType.CLEAR_OFFICE_VISIT_DATA:
      return {
        ...state,
        singleData: {},
      };
    case actionType.OPEN_OFFICE_VISIT_DRAWER:
      return {
        ...state,
        drawerIsOpen: true,
      };
    case actionType.CLOSE_OFFICE_VISIT_DRAWER:
      return {
        ...state,
        drawerIsOpen: false,
      };
    case actionType.OPEN_OFFICE_VISIT_DETAILS_DRAWER:
      return {
        ...state,
        detailDrawerIsOpen: true,
      };
    case actionType.CLOSE_OFFICE_VISIT_DETAILS_DRAWER:
      return {
        ...state,
        detailDrawerIsOpen: false,
      };
    case actionType.OFFICE_VISIT_TABLE_REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };
    default:
      return state;
  }
};

export { officeVisitReducer };
