import React, { useEffect } from 'react';
import { WorkflowList as WorkflowListComponent } from '@admin/shared';
import { useDispatch } from 'react-redux';
import { workflowTypeActions } from '@admin/core';

const WorkflowList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workflowTypeActions.getWorkflowTypesRequest());
  }, [dispatch]);

  return <WorkflowListComponent />;
};

export { WorkflowList };
