import React from 'react';
import { getInstitutions } from '@crm/services.api';
import {
  IInstitutionBranch,
  ITableNameCardProps,
  ITableProps,
} from '@shared-components/models';
import {
  ADD_INSTITUTION,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  EDIT_BTN,
  INSTITUTE_TITLE,
  LABEL,
  PRODUCT_TITLE,
  URL_INSTITUTION_DETAIL_LINK,
} from '@moxie/constants';
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { BankOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  CrmTable,
  InstitutionBranchTagGroup,
  popConfirm,
} from '@shared-components/elements';
import { useDispatch } from 'react-redux';
import { institutionActions } from '@crm/core';
import { normalizePhrase } from 'libs/shared/src/functions';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const { Text } = Typography;

export const useColumns = (props: ITableProps) => {
  const { deleteData, showDrawer, setIsEdit } = props;

  const cols = [
    {
      title: LABEL.NAME,
      key: 'name',
      dataIndex: 'name',
      width: 300,
      sorter: () => 0,
      render: (name: string, data: any) =>  {
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };

        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
          setIsEdit: setIsEdit,
        };

        return (
          <InstitutionTableNameCard
            menuActions={menuActions}
            id={data?.id}
            firstName={data?.name}
            email={data.email}
            index={data?.id}
          />
        );
      },
    },
    {
      title: LABEL.SERVICE,
      dataIndex: 'service',
      width: 150,
      render(data: { name: string }) {
        return <div className="initial_capital">{data?.name}</div>;
      },
      key: 'service',
    },
    {
      title: LABEL.BRANCHES,
      dataIndex: 'branches',
      width: 200,
      key: 'branches',
      render(data: IInstitutionBranch[]) {
        return <InstitutionBranchTagGroup data={data} />;
      },
    },
    {
      title: LABEL.WORKFLOW,
      dataIndex: 'workflows',
      width: 200,
      key: 'workflows',
      render(data: any) {
        return (
          <div>
            {data?.map((item: any) => {
              return (
                <Tag key={item?.id} color="default" className="initial_capital">
                  {item?.workflowType?.name}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: PRODUCT_TITLE,
      dataIndex: 'productCount',
      render(productCount:string){
        return <div>{productCount}</div>
      }
    },
  ];

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c))
  }, [])

}

export const InstitutionTableNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  email,
  id,
  menuActions,
}: ITableNameCardProps) => {
  const canAccess = useCanAccess();
  const {
    data,
    deleteData,
    showDrawer,
    setIsEdit,
  } = menuActions;

  const menuItems = [
    canAccess('institution', 'update') && (<Menu.Item
      key={1}
      onClick={() => {
        showDrawer(data.id);
        setIsEdit(true);
      }}
    >
      <Space direction="horizontal">
        <EditOutlined data-testid="crm-editinstitution-menu" />
        {EDIT_BTN}
      </Space>
    </Menu.Item>),
    canAccess('institution', 'delete') && (<Menu.Item
      key={2}
      danger
      onClick={() => {
        popConfirm(
          CONFIRM_TITLE.DELETE_INSTITUTION,
          CONFIRM_MESSAGE.DELETE_INSTITUTION,
          () => {
            deleteData();
          }
        );
      }}
    >
      <Space direction="horizontal">
        <DeleteOutlined data-testid="crm-deleteinstitution-menu" />
        {DELETE_BTN}
      </Space>
    </Menu.Item>)
  ]
    .filter(Boolean);

  return (
    <Row justify="space-around">
      <Col span={22}>
        <div className="table-user-card">
          <Link to={`${URL_INSTITUTION_DETAIL_LINK}${id}/branches`}>
            <Avatar icon={<BankOutlined />} className="margin-right-2" />
          </Link>
          <div className="table-user-card__meta">
            <Link to={`${URL_INSTITUTION_DETAIL_LINK}${id}/branches`}>
              <Text
                className="text-primary-color ellipsis-text initial_capital"
                ellipsis={
                  String(firstName).length >= 45
                    ? { tooltip: firstName }
                    : false
                }
              >
                {normalizePhrase(firstName)}
              </Text>
              <Text className="text-primary-color">
                <small>{email?.toLowerCase()}</small>
              </Text>
            </Link>
          </div>
        </div>
      </Col>
      {/* we had to check the length of menuAction greater than one
        because we have data field in menuAction but doesnt have other actions like
        delete, update */}
      {(menuActions && Object.keys(menuActions).length > 1 && menuItems.length > 0) && (
        <Col span={1} className="user-name-card__action">
          <Dropdown overlay={<InstitutionTitleMenu menuItems={menuItems} />}>
            <Button
              data-testid="crm-editdeleteinstitution-btn"
              type="text"
              className="table-cell-action-btn"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};

const InstitutionTitleMenu: React.FC<{ menuItems: React.ReactNode[] }> = ({
  menuItems
}) => {
  return (
    <Menu>
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          {item}
        </React.Fragment>))}
    </Menu>
  );
};



const InstitutionTableList = ({
  handleDrawerEvent,
  handleEditEvent,
  refreshTable,
  triggerRefresh,
  setIsEdit,
}: any) => {
  const dispatch = useDispatch();
  const canAccess = useCanAccess();

  const getData = async (params: Record<string, unknown>) => {
    const ins = await getInstitutions(params);
    return ins;
  };

  const showDrawer = (id?: number) => {
    if (id) {
      dispatch(institutionActions.getInstitutionRequest(id));
    }
    setIsEdit(false);
    handleDrawerEvent(true);
  };

  const columnActions = {
    deleteData: (id: number) => {
      dispatch(institutionActions.deleteInstitutionRequest(id, triggerRefresh));
    },
    showDrawer: showDrawer,
    setIsEdit: handleEditEvent,
  };

  const columns = useColumns(columnActions);

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        refresh={refreshTable}
        tableName={INSTITUTE_TITLE}
        columns={columns}
        action={getData}
        className="institution-table"
        tableButtons={
          <>
            {
              canAccess('institution', 'create') ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {/* <Menu.Item key="0">
                        <Link to={URL_INSTITUTE_IMPORT}>{LABEL.IMPORT_EXISTING}</Link>
                      </Menu.Item> */}
                      <Menu.Item
                        data-testid="crm-create-newinstituion"
                        key="1"
                        onClick={() => {
                          showDrawer && showDrawer();
                        }}
                      >
                        {LABEL.CREATE_NEW}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button
                    type="primary"
                    title={ADD_INSTITUTION}
                    typeof="button"
                    data-testid="crm-addinstitutionbutton"
                  >
                    <PlusOutlined /> {ADD_INSTITUTION}
                  </Button>
                </Dropdown>

              ) : null
            }
          </>
        }
      />
    </div>
  );
};

export default InstitutionTableList;
