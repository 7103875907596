import {
  CaretRightOutlined,
  ClockCircleOutlined,
  EditOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { officeVisitActions, serviceActions, useAppSelector } from '@crm/core';
import { fetchPrevVisitByContact, queryAssignees } from '@crm/services.api';
import {
  ACTIVITY_LOG,
  ASSIGNEE_LABEL,
  CANCEL_BTN,
  CONTACT_VISIT_RESPONSE_MESSAGE,
  DRAWER_WIDTH,
  GUTTER,
  LABEL,
  PREVIOUS_VISITS,
  SESSION_NOTE_REQUIRED,
  UNASSIGNED_LABEL,
  UPDATE_BTN,
  URL_DETAIL_LINK,
  USER_ADD_PLACEMENT,
  VISIT_DETAILS,
  VISIT_PURPOSE,
} from '@moxie/constants';
import {
  DrawerElem,
} from '@shared-components/elements';
import { Editor } from '@shared-components/forms';
import { IOfficeVisitCrm } from '@shared-components/models';
import { IOfficeVisitDetailsDrawerComponent } from '@model/crm/office-visit';
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OfficeVisitActivityLog } from './office-visit-activity-log';
import { UserCard } from './user-card';
import { Link } from 'react-router-dom';
import { Countdown } from './office-visit-table-column';
import { errorNotificationHandler } from 'libs/shared/src/functions';
import { removeDefTags } from './removeTagFn';
import { UnassignedCard, UserNameCard } from '../../shared';
import SearchBox from '../../shared/search-box';
import { Assignee } from '../../user/user.model';
import { useQueryClient } from '@tanstack/react-query';

const antIcon = <LoadingOutlined className="font-large" spin />;

const WaitAfterSessionStart = ({
  sessionStart,
  createdAt,
}: {
  sessionStart: string | undefined;
  createdAt: Date;
}) => {
  const difference = moment.duration(
    moment(sessionStart).diff(moment(createdAt))
  );
  return (
    <>
      <Typography.Text>
        {difference.get('h') !== 0 && difference.get('h') + 'h'}{' '}
        {difference.get('m')}m
      </Typography.Text>
    </>
  );
};

const OfficeVisitDetailsDrawer: React.FC<IOfficeVisitDetailsDrawerComponent> = ({
  visible,
  onClose,
  setDrawerOpen,
  forContact
}: IOfficeVisitDetailsDrawerComponent) => {
  const queryClient = useQueryClient();
  const { initialData, isInitialDataLoading, loggedUserBranchId } = useAppSelector((state) => ({
    initialData: state.office_visits.singleData,
    isInitialDataLoading: state.office_visits.loading,
    loggedUserBranchId: state.auth.user?.branchId,
  }));

  const [isEditing, setIsEditing] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [prevVisits, setPrevVisits] = useState([]);
  const [prevVisitsLoading, setPrevVisitsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [noteDisabled, setNoteDisabled] = useState<boolean>(true);

  const user = useAppSelector((state) => (state.auth.user));

  const dispatch = useDispatch();

  // const socket = useAppSelector((state) => state.socket.wss);
  // const branches = useAppSelector((state) => state.office_branches.allData);

  const checkCanAttendEndSession = (): boolean => {
    if (loggedUserBranchId === initialData?.branchId) {
      return !(user?.id === initialData?.assigneeId);
    }
    return true;
  };

  const editNotesClick = () => {
    setIsEditing(true);
  };

  const handleEditNotes = (data: any) => {
    if (data && noteValue !== data) {
      setNoteValue(data);
      setNoteDisabled(false);
    } else {
      setNoteDisabled(true);
    }
  };

  const cancelEditor = () => {
    setNoteDisabled(true);
    setIsEditing(false);
  };

  const handleSaveNotes = () => {
    const sessionNote = removeDefTags(noteValue);
    if (sessionNote.length === 0) {
      setNoteDisabled(true);
      errorNotificationHandler(SESSION_NOTE_REQUIRED);
    } else {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(initialData.id, {
          sessionNotes: noteValue,
        })
      );
      setIsEditing(false);
      dispatch(officeVisitActions.getOfficeVisitRequest(initialData.id));
    }
  };

  const handleAttendSession = () => {
    const updateData: Partial<IOfficeVisitCrm> = {
      status: 'Attending',
      sessionStart: moment(),
      assigneeId: user?.id,
    };

    dispatch(
      officeVisitActions.updateOfficeVisitRequest(
        initialData.id,
        updateData,
        () => {
          triggerRefresh();
          if (forContact) {
            queryClient.invalidateQueries({
              queryKey: ['contact-office-visits']
            })
          }
          //   socket?.emit('server::office-visit-updated', {
          //     assignee: initialData.assignee.id as string,
          //     branch: branches.find(
          //       (branch) => branch.id === initialData.branch_id
          //     )?.name as string,
          //     contact_id: initialData.contact_id,
          //     crm_user_id: user?.id as string,
          //     type: 'attended',
          //   });
        }
      )
    );
    onClose();
  };

  const handleEndSession = () => {
    const sessionNote = removeDefTags(noteValue);
    const oldNote = removeDefTags(initialData?.sessionNotes);
    if (sessionNote.length === 0 && oldNote.length === 0) {
      errorNotificationHandler(SESSION_NOTE_REQUIRED);
      setDrawerOpen();
    } else {
      let updateData: Partial<IOfficeVisitCrm> = {
        status: 'Completed',
        sessionEnd: moment(),
      };
      if (!noteDisabled) {
        updateData = {
          ...updateData,
          sessionNotes: sessionNote.length > 0 ? noteValue : initialData?.sessionNotes,
        }
      }
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData.id,
          updateData,
          () => {
            triggerRefresh();
            if (forContact) {
              queryClient.invalidateQueries({
                queryKey: ['contact-office-visits']
              })
            }
          }
        )
      );
      setNoteValue('');
      onClose();
    }
    setIsEditing(false);
  };

  const editAssigneeClick = () => {
    setIsEdit(true);
  };

  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const handleEditCancel = () => {
    setIsEdit(false);
    setNoteDisabled(true);
  };

  const updateAssignee = (assigneeId: string) => {
    // const removedAssignee = initialData?.assignee?.id ?? null;
    if (assigneeId === '' || !assigneeId) {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData?.id,
          {
            assigneeId: null,
          },
          () => {
            triggerRefresh();
            if (forContact) {
              queryClient.invalidateQueries({
                queryKey: ['contact-office-visits']
              })
            }
            //   socket?.emit('server::office-visit-updated', {
            //     assignee: assigneeId,
            //     removed_assignee: removedAssignee,
            //     branch: branches.find(
            //       (branch) => branch.id === initialData.branch_id
            //     )?.name as string,
            //     contact_id: initialData.contact.id,
            //     crm_user_id: user?.id as string,
            //     type: 'changed',
            //   });
          }
        )
      );
    } else {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData?.id,
          {
            assigneeId,
          },
          () => {
            triggerRefresh();
            if (forContact) {
              queryClient.invalidateQueries({
                queryKey: ['contact-office-visits']
              })
            }
            //   socket?.emit('server::office-visit-updated', {
            //     assignee: assigneeId,
            //     removed_assignee: removedAssignee,
            //     branch: branches.find(
            //       (branch) => branch.id === initialData.branch_id
            //     )?.name as string,
            //     contact_id: initialData.contact.id,
            //     crm_user_id: user?.id as string,
            //     type: 'changed',
            //   });
          }
        )
      );
    }

    dispatch(officeVisitActions.getOfficeVisitRequest(initialData?.id));
    dispatch(officeVisitActions.officeVisitTableRefresh());
    handleEditCancel();
  };

  const difference = moment.duration(
    moment(initialData?.sessionEnd).diff(moment(initialData?.sessionStart))
  );

  const contactFullName =
    initialData?.contact?.firstName + ' ' + initialData?.contact?.lastName;

  useEffect(() => {
    const getPrevVisits = async (contactId: string) => {
      setPrevVisitsLoading(true);
      const res = await fetchPrevVisitByContact(contactId, { limit: 3 });
      const filterCurrentVisit = await res?.data?.data.filter((officeVisit: IOfficeVisitCrm) => officeVisit.id !== initialData?.id)
      setPrevVisits(filterCurrentVisit);
      setPrevVisitsLoading(false);
    };

    if (initialData?.contactId) {
      getPrevVisits(initialData?.contactId);
    }
  }, [initialData?.id]);

  useEffect(() => {
    dispatch(serviceActions.getServicesRequest());
  }, []);


  const handleClose = () => {
    setIsEditing(false);
    onClose();
  }

  return (
    <DrawerElem
      visible={visible}
      onClose={handleClose}
      title={
        initialData?.status === 'Waiting' ? (
          <div>
            {VISIT_DETAILS}
            <Tag color="warning" className="margin-left-2">
              {initialData.status}
            </Tag>
          </div>
        ) : (
          <div>
            {VISIT_DETAILS}
            <Tag className="green-btn margin-left-2">{initialData.status}</Tag>
          </div>
        )
      }
      width={DRAWER_WIDTH}
      placement={USER_ADD_PLACEMENT}
    >
      <Spin spinning={isInitialDataLoading}>
        <Row gutter={GUTTER} className="margin-bottom-1">
          <Col className="display-flex" span={10}>
            <div className="profile__info__item profile__info__user">
              <Avatar
                size="default"
                className="initial_capital margin-right-1"
              >
                {initialData?.contact?.firstName?.substring(0, 1)}
                {initialData?.contact?.lastName
                  ?.substring(0, 1)
                  .toUpperCase()}
              </Avatar>
              <div className="profile__info__user__detail initial_capital">
                <Link
                  to={`${URL_DETAIL_LINK}${initialData?.contact?.id}/activities`}
                >
                  <Typography.Title
                    level={3}
                    className="name-fixed-width text-primary"
                    ellipsis={
                      contactFullName.length > 30
                        ? { tooltip: contactFullName }
                        : false
                    }
                  >
                    {contactFullName}
                  </Typography.Title>
                </Link>
                <Tag className="visit-details-tag">
                  {initialData?.contact?.status}
                </Tag>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <Typography.Text className="text-primary">
              {VISIT_PURPOSE}
            </Typography.Text>
            <br />
            <Typography.Text>
              {initialData?.visitPurpose?.visitPurpose}
            </Typography.Text>
          </Col>
          <Col span={6}>
            {initialData.status === 'Attending' ? (
              <Link to={forContact ? {} : removeDefTags(noteValue).length > 0 ? `/office-visit/completed` : `/office-visit/attending`}>
                <Button
                  className="emerald-green-btn margin-right-1"
                  onClick={handleEndSession}
                  disabled={checkCanAttendEndSession()}
                  data-testid="crm-officevisitdetaildrawer-endbtn"
                >
                  <CheckCircleOutlined />
                  {LABEL.END_SESSION}
                </Button>
              </Link>
            ) : initialData?.status === 'Waiting' ? (
              <Link to={forContact ? {} : `/office-visit/attending`}>
                <Button
                  className="green-btn margin-right-1"
                  onClick={handleAttendSession}
                  disabled={checkCanAttendEndSession()}
                  data-testid="crm-officevisitdetaildrawer-attendbtn"
                >
                  <CaretRightOutlined />
                  {LABEL.ATTEND_SESSION}
                </Button>
              </Link>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={24}>
            <Row className="visit-details-title">
              <h4 className="margin-left-1 margin-top-1">
                {LABEL.CHECK_IN_DETAILS}
              </h4>
            </Row>
            <Row>
              <Col span={5}>
                <h4>{LABEL.DATE}</h4>
                <Typography.Text>
                  {moment(initialData.createdAt).format('DD/MM/yyyy')}
                </Typography.Text>
              </Col>

              <Col span={5}>
                <h4>{LABEL.SESSION_START}</h4>
                {initialData.sessionStart ? (
                  <Typography.Text>
                    {moment(initialData.sessionStart).format('h:mm A')}
                  </Typography.Text>
                ) : (
                  <p>-</p>
                )}
                <h4 className="margin-top-1">{LABEL.SESSION_END}</h4>
                {initialData.sessionEnd ? (
                  <Typography.Text>
                    {moment(initialData.sessionEnd).format('h:mm A')}
                  </Typography.Text>
                ) : (
                  <p>-</p>
                )}
              </Col>
              <Col span={6}>
                <Row className="wait-time">
                  <Col className="display-flex" span={24}>
                    <div className="profile__info__item profile__info__user">
                      <ClockCircleOutlined className="wait-time-icon" />
                      <div>
                        <h4>{LABEL.WAIT_TIME}</h4>
                        {initialData?.status === 'Waiting' ? (
                          <Countdown data={initialData?.createdAt} />
                        ) : (
                          <WaitAfterSessionStart
                            sessionStart={initialData?.sessionStart}
                            createdAt={initialData?.createdAt}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="wait-time">
                  <Col className="display-flex" span={24}>
                    <div className="profile__info__item profile__info__user">
                      <ClockCircleOutlined className="wait-time-icon" />
                      <div>
                        <h4>{LABEL.ATTEND_TIME}</h4>
                        {!['Attending', 'Waiting'].includes(initialData.status) ? (
                          <Typography.Text>
                            {difference.get('h') !== 0 &&
                              difference.get('h') + 'h'}{' '}
                            {difference.get('m')}m
                          </Typography.Text>
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <h4>{ASSIGNEE_LABEL}</h4>
                {initialData.status === 'Waiting' ? (
                  <Row>
                    <>
                      {initialData?.assignee?.id && !isEdit ? (
                        <div
                          className="cursor-pointer"
                          data-testid="crm-officevisitdetaildrawer-assignee"
                          onClick={editAssigneeClick}
                        >
                          <UserNameCard
                            index={initialData?.assignee?.id}
                            firstName={initialData?.assignee?.firstName}
                            lastName={initialData?.assignee?.lastName}
                            email={initialData?.assignee?.email}
                            id={initialData?.assignee?.id}
                            actionBar={false}
                          />
                        </div>
                      ) : isEdit ? (
                        <Row align="middle">
                          <Col span={4}>
                            <Tooltip title="unassigned">
                              <Avatar
                                icon={<UserOutlined />}
                                alt="unassigned"
                              />
                            </Tooltip>
                          </Col>
                          <Col span={18}>
                            <Form layout="inline">
                              <Space direction="horizontal">
                                <Form.Item
                                  name="assigneeId"
                                  className="office-visit__assignee-select-width"
                                >
                                  <SearchBox
                                    hideDefaultOptionView
                                    defaultValue={initialData?.assignee?.id}
                                    onBlur={() => setIsEdit(false)}
                                    fetchQuery={queryAssignees}
                                    filter={{
                                      branches: [loggedUserBranchId]
                                    }}
                                    onChange={updateAssignee}
                                    emptyOption={<Select.Option value="" label={UNASSIGNED_LABEL}> <UnassignedCard /> </Select.Option>}
                                    optionLabelProp='label'
                                  >
                                    {
                                      (option: Assignee) => {
                                        const { id, firstName, lastName, email } = option;
                                        return (
                                          <>
                                            <Select.Option
                                              value={id}
                                              label={`${firstName} ${lastName}`}
                                            >
                                              <UserCard
                                                firstName={firstName}
                                                lastName={lastName}
                                                email={email}
                                                index={id}
                                              />
                                            </Select.Option>
                                          </>
                                        )
                                      }
                                    }
                                  </SearchBox>
                                </Form.Item>
                              </Space>
                            </Form>
                          </Col>
                        </Row>
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={editAssigneeClick}
                        >
                          <UnassignedCard />
                        </div>
                      )}
                    </>
                  </Row>
                ) : (
                  <div>
                    <UserCard
                      firstName={initialData?.assignee?.firstName}
                      lastName={initialData?.assignee?.lastName}
                      email={initialData?.assignee?.email}
                      showEmailAsLink={false}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={GUTTER}>
          <Col span={24}>
            <Row align='middle' className="visit-details-title">
              <h4 className="margin-left-1 margin-top-1">
                {LABEL.SESSION_NOTES}
              </h4>
              <EditOutlined
                className="margin-left-2"
                onClick={editNotesClick}
              />
            </Row>
            <Card size="small" className='padding-left-1'>
              <Row gutter={GUTTER}>
                <Col span={24}>
                  {isEditing ? (
                    <>
                      <Editor
                        type="text"
                        onChange={handleEditNotes}
                        value={initialData.sessionNotes}
                      />
                      <div className="padding-top-2 text-align-right">
                        <Space direction="horizontal">
                          <Button
                            className="lead-margin-right"
                            onClick={cancelEditor}
                          >
                            {CANCEL_BTN}
                          </Button>
                          <Button
                            type="primary"
                            onClick={handleSaveNotes}
                            htmlType="submit"
                            disabled={noteDisabled}
                          >
                            {UPDATE_BTN}
                          </Button>
                        </Space>
                      </div>
                    </>
                  ) :
                    removeDefTags(initialData.sessionNotes).length > 0 ?
                      (
                        <Row>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `${initialData.sessionNotes ?? ''}`,
                            }}
                          ></p>
                        </Row>
                      ) :
                      (
                        <Typography.Text italic={true} className='text-grey' >{SESSION_NOTE_REQUIRED}</Typography.Text>
                      )
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={GUTTER}>
          <Col span={24}>
            <Row className=" visit-details-title">
              <h4 className="margin-left-1 margin-top-1">
                {PREVIOUS_VISITS}
              </h4>
            </Row>
            <Card size="small" className="prev-visit-card">
              {prevVisitsLoading ? (
                <div className="prev-visit-loader align-items-center">
                  <Spin indicator={antIcon} />
                </div>
              ) : !prevVisitsLoading && prevVisits.length !== 0 ? (
                <>
                  <Row>
                    <Col span={12}>
                      <h3 className="font-weight-normal">
                        {ASSIGNEE_LABEL}
                      </h3>
                    </Col>
                    <Col span={9}>
                      <h3 className="font-weight-normal color-primary">
                        {VISIT_PURPOSE}
                      </h3>
                    </Col>
                    <Col span={3}></Col>
                  </Row>
                  {prevVisits.map((item: any) => {
                    const fullName =
                      item?.assignee?.firstName +
                      ' ' +
                      item?.assignee?.lastName;
                    return (
                      <Row className="margin-bottom-2" key={item?.id}>
                        <Col className="display-flex" span={12}>
                          {item?.assignee ? (
                            <div className="profile__info__item profile__info__user">
                              <Avatar
                                size="default"
                                className="initial_capital margin-right-1"
                              >
                                {item?.assignee?.firstName?.substring(
                                  0,
                                  1
                                )}
                                {item?.assignee?.lastName?.substring(0, 1)}
                              </Avatar>
                              <div className="profile__info__user__detail initial_capital">
                                <Typography.Title
                                  level={3}
                                  className="name-fixed-width"
                                  ellipsis={
                                    fullName.length > 30
                                      ? { tooltip: fullName }
                                      : false
                                  }
                                >
                                  {fullName}
                                </Typography.Title>
                                <Tag>{item?.assignee?.branch?.name}</Tag>
                              </div>
                            </div>
                          ) : (
                            <div className="profile__info__item profile__info__user">
                              <Avatar
                                size="default"
                                icon={<UserOutlined />}
                                className="initial_capital margin-right-1"
                                alt="assignee"
                              />
                              <div className="profile__info__user__detail initial_capital">
                                <Typography.Title
                                  level={3}
                                  className="name-fixed-width"
                                >
                                  {UNASSIGNED_LABEL}
                                </Typography.Title>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col span={9}>
                          <Typography.Text className="text-primary">
                            {item?.service?.name}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {item?.visitPurpose?.visitPurpose}
                          </Typography.Text>
                        </Col>
                        <Col span={3}>
                          <Typography.Text
                            type="secondary"
                            className="ant-row-end"
                          >
                            {moment(item?.createdAt).format('DD/MM/yyyy')}
                          </Typography.Text>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <div className="prev-visit-loader align-items-center">
                  <Empty
                    description={
                      CONTACT_VISIT_RESPONSE_MESSAGE.PREVIOUS_VISIT_NOT_FOUND
                    }
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>

        <Row gutter={GUTTER}>
          <Col span={24}>
            <Row className="visit-details-title">
              <h4 className="margin-left-1 margin-top-1">{ACTIVITY_LOG}</h4>
            </Row>
            <Row>
              <OfficeVisitActivityLog
                id={initialData?.id}
                initialData={initialData}
              />
            </Row>
          </Col>
        </Row>
      </Spin>
    </DrawerElem >
  );
};

export { OfficeVisitDetailsDrawer };
