import React, { useEffect, useState } from 'react';
import { TEXT } from '@moxie/constants';
import { OverViewHeader } from '@shared-components/elements';
import { UserTabSection } from '@admin/shared';
import { useDispatch } from 'react-redux';
import { authActions, useAppSelector, userActions } from '@admin/core';
import { errorHandler } from '@moxie/utils';
import { UserProfile } from './user-profile';

export const useProfile = (): [loading: boolean] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchLeadData = () => {
      try {
        setLoading(true);
        dispatch(authActions.getAuthUser());
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeadData();
  }, []);

  return [loading];
};

const DashboardProfile: React.FC = () => {
  const dispatch = useDispatch();
  const authUser: any = useAppSelector((state) => state.auth.user);
  const statUser = useAppSelector((state) => state.users.singleData);
  useProfile();

  useEffect(() => {
    dispatch(userActions.fetchUser(authUser?.id));
    return () => {
      dispatch(userActions.clearFetchUser());
    };
  }, [authUser]);

  return (
    <>
      <OverViewHeader title={TEXT.PROFILE} />
      {statUser && Object.keys(statUser).length > 0 && (
        <UserProfile data={statUser} />
      )}
      {statUser && Object.keys(statUser).length > 0 && (
        <UserTabSection lead={statUser} />
      )}
    </>
  );
};

export default DashboardProfile;
