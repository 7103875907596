import React from 'react';
import * as ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import App from './pages';
import '@moxie/styles';
import { Provider } from 'react-redux';
import store from './core/store';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
