import React, { useEffect } from 'react';
import { ApplicationListComponent } from '@admin/shared';
import { useDispatch } from 'react-redux';
import { workflowActions } from '@admin/core';

const ApplicationList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workflowActions.getWorkflowsRequest());
  }, []);

  return <ApplicationListComponent />;
};

export { ApplicationList };
