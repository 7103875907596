import React from 'react';
import { Route } from 'react-router-dom';
import { AdminSettingsLayout, RoleLists } from '@admin/shared';

const GeneralSettings: React.FC = () => {
  return (
    <>
      <AdminSettingsLayout>
        <Route
          exact
          path="/dashboard/settings/roles"
          component={RoleLists}
        />
      </AdminSettingsLayout>
    </>
  );
};

export default GeneralSettings;
