import { notification } from 'antd';
import { errorHandler } from '@moxie/utils';
import { IDeleteMultipleFn } from '@shared-components/models';
import moment from 'moment';

export const globalHandleCheck = (
  e: any,
  leadForm: any,
  setLeadForm: any,
  name: string,
  // eslint-disable-next-line no-unused-vars
  setDisabled: (data: boolean) => void
) => {
  setDisabled(false);
  setLeadForm({
    ...leadForm,
    [name]: {
      ...leadForm[name],
      [e.target.name]: e.target.checked,
    },
  });
};

export const performMultipleAction = async ({
  deleteFunction,
  data,
}: IDeleteMultipleFn) => {
  try {
    const response = await deleteFunction(data);
    return successNotificationHandler(response.data.message);
  } catch (error) {
    errorHandler(error);
  }
};

export const capitalizeFirstLetter = (name: string): string => {
  return name?.charAt(0)?.toUpperCase() + name?.slice(1);
};

export const capitalizeAllLetter = (name: string): string => {
  return name?.toUpperCase();
};
export const preventMinus = (e: any) => {
  if (e.code === 'Minus') {
    e.preventDefault();
  }
};

export const formatDate = (date: any, format = 'YYYY-MM-DD') => {
  return moment(date).format(format);
};

export const formatDateWithTimezone = (
  date: string,
  timezone: string
): string => {
  return moment.tz(date, timezone).format('MMM DD, YYYY');
};

export const parseDateAccordingToTimezone = (date: string,
  timezone: string) => {
  return moment.tz(date, timezone)
}

export const formExtraNotes = (name: string) => {
  return `*Note: Please select ${name} first`;
};
export const generateLeadsFormUrl = (id: string | undefined): string => {
  return `${window.location.origin}/online-form?i=${id}`;
};

export const successNotificationHandler = (
  message: string | undefined,
  duration?: number
): void => {
  notification.success({
    className: 'notification-success',
    message: message || '',
    placement: 'bottomLeft',
    duration,
  });
};

export const warnNotificationHandler = (
  message: string,
  duration?: number
): void => {
  notification.warn({
    className: 'notification-warning',
    message: message,
    placement: 'bottomLeft',
    duration,
  });
};
export const errorNotificationHandler = (
  description: string,
  duration?: number
): void => {
  notification.error({
    className: 'notification-error',
    message: 'Error',
    description,
    placement: 'bottomLeft',
    duration,
  });
};

export const getFileName = (str: string): string => {
  return str.split('.').slice(0, -1).join('.');
};

export const getExtension = (str: string): string => {
  const extension = str.split('.').slice(-1)[0];
  return '.' + extension;
};

export function changeNullToUndefined(object: any): any {
  Object.keys(object).forEach((key: string) => {
    if (object[key] === null || object[key] === '') object[key] = undefined;
  });
  return object;
}

export const disableFutureDate = (currentDate: any) => {
  const customDate = moment().format('YYYY-MM-DD');
  return currentDate && currentDate > moment(customDate, 'YYYY-MM-DD');
};

export const disablePastDate = (currentDate: any) => {
  const customDate = moment().format('YYYY-MM-DD');
  return currentDate && currentDate < moment(customDate, 'YYYY-MM-DD');
};

export const normalizePhrase = (phrase: string | undefined) => {
  return phrase
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


export const capitalizeTrimWord = (sentence: string) => sentence.replace(/\b\w/g, (c) => c.toUpperCase()).replace(/\s+/g, ' ').trim();
