import {
  ApplicationAssigneePayload,
  BasicNotificationPayload,
  ContactOfficeVisitPayload,
  IAction,
  Notification,
} from '@shared-components/models';
import { socketSagaActions } from './socket.action';
import { Socket as WsSocket } from 'socket.io-client';
import { ActivitiesType, ActivityAction } from '@model/contact-activity';
import { ContactBranchPayload } from '../../../../../libs/model/notification';

export const SOCKET_FEATURE_KEY = 'socket';

interface ProfileUpdatedPayload {
  activitiesTypeId?: string;
  activitiesType: ActivitiesType;
  userId?: string;
  activitiesAction: ActivityAction;
  previousAssignedUserId?: string | null;
  assignedUserId?: string | null;
  contactId: string;
  companyId: string;
  data: unknown;
  documentType?: string;
}

interface FollowersUpdatedPayload {
  contactId?: string;
  companyId?: string;
  userId?: string;
  data: unknown;
  addedFollowers: string[];
  removedFollowers: string[];
  remainingFollowers: string[];
  activitiesTypeId: string;
}

export interface AssigneeUpdatedPayload extends BasicNotificationPayload {
  removedAssignee: string | null;
  receiverId: string | null;
  activitiesType?: ActivitiesType;
  activitiesAction?: ActivityAction;
  activitiesTypeId?: string;
  previousAssignedUserId?: string | null;
}

export interface BasicDetailUpdatedPayload extends BasicNotificationPayload{
  activitiesType: ActivitiesType;
  activitiesAction: ActivityAction;
  activitiesTypeId: string;
}

export interface ArchivePayload {
  userId: string;
  contactId: string;
  companyId: string;
  archived: boolean;
  activitiesType?: ActivitiesType;
  activitiesTypeId?: string;
  activitiesAction?: ActivityAction;
  data?:{
    archived:boolean
  }
}

interface ServerToClientEvents {
  'client::update-activity-logs': (contact_id: string) => void;
  'client::update-application-logs': (application_id: string) => void;
  'client::notification-updated': (notification: Notification) => void;
}

interface ClientToServerEvents {
  'server::followers-updated': (payload: FollowersUpdatedPayload) => void;
  'server::profile-updated': (payload: ProfileUpdatedPayload) => void;
  'server::application-updated': (payload: ProfileUpdatedPayload) => void;
  'server::join-room': (payload: { userId: string; socketId: string }) => void;
  'server::leave-room': (payload: { userId: string; socketId: string }) => void;
  'server::assignee-updated': (payload: AssigneeUpdatedPayload) => void;
  'server::basic-detail-updated': (payload: BasicDetailUpdatedPayload) => void;
  'server::application-assignee-updated': (
    payload: ApplicationAssigneePayload
  ) => void;
  'server::office-visit-updated': (payload: ContactOfficeVisitPayload) => void;
  'server::branches-updated': (payload: ContactBranchPayload) => void;
  'server::archive-status-updated': (payload: ArchivePayload) => void;
}

export type Socket = WsSocket<ServerToClientEvents, ClientToServerEvents>;
export interface SocketState {
  wss: Socket | null;
}

export const initialSocketState: SocketState = {
  wss: null,
};
export const socketReducer = (
  state = initialSocketState,
  action: IAction<any>
): SocketState => {
  switch (action.type) {
    case socketSagaActions.SET_SOCKET_CONNECTION:
      return {
        wss: action.payload,
      };
    case socketSagaActions.REMOVE_SOCKET_CONNECTION:
      return {
        wss: null,
      };

    default:
      return state;
  }
};
