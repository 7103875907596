import React from 'react';
import { Card } from 'antd';
import { LoginModule } from '@admin/shared';

const Login: React.FC = () => {
  return (
    <div className="login-layout">
      <section className={`auth-layout auth-layout--login`}>
        <div className="auth-layout__card">
          <Card className="padding-2">
            <LoginModule />
          </Card>
        </div>
      </section>
    </div>
  );
};

export default Login;
