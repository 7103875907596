import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CRM_COOKIE_ACCESS } from '@moxie/constants';
import { Spin } from 'antd';
import { CrmDashboardLayout } from '@crm/dashboard';
import { useDispatch } from 'react-redux';
import { authActions, useAppSelector } from '@crm/core';

const CrmProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  const [access] = useState(Cookies.get(`${CRM_COOKIE_ACCESS}`));
  const dispatch = useDispatch()
  const { loading, user } = useAppSelector(store => store.auth);

  useEffect(() => {
    if (!user) {
      dispatch(authActions.getAuthUser())
    }
  }, [dispatch, user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return (
            <div className="page-load">
              <Spin />
            </div>
          )
        }
        if (!access) {
          return <Redirect to="/" />
        }

        return (
          <CrmDashboardLayout>
            <React.Suspense
              fallback={
                <div className="page-load">
                  <Spin />
                </div>
              }
            >
              <Component {...props} />
            </React.Suspense>
          </CrmDashboardLayout>
        )
      }

      }
    />
  );
};

export { CrmProtectedRoute };
