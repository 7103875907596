import { CaretRightOutlined } from '@ant-design/icons';
import {
  DrawerElem,
  FormItem,
  Option,
  SearchableSelect,
} from '@shared-components/elements';
import { IGetPopupContainer, IOfficeBranchCRM } from '@shared-components/models';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Spin,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RootState } from 'apps/crm/src/core/store';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADDRESS1_LABEL,
  ADDRESS1_PLACEHOLDER,
  ADDRESS2_LABEL,
  ADDRESS2_PLACEHOLDER,
  ADDRESS_INFORMATION,
  ALTERNATIVE_PHONE_LABEL,
  AUTH_MESSAGE,
  CANCEL_BTN,
  CHANGE_TIMEZONE,
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  CONTACT_INFORMATION,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  DRAWER_WIDTH,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  FORM_SIZE,
  GUTTER,
  JOB_INFORMATION,
  LABEL,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  PLACEHOLDER,
  ROLE_LABEL,
  ROLE_PLACEHOLDER,
  SAVE_BTN,
  TEXT,
  TIMEZONE,
  UPDATE_BTN,
  USER_ADD_PLACEMENT,
  USER_ADD_TITLE,
  USER_EDIT_TITLE,
  regex,
} from '../../../../../libs/constants/src/index';
import { officeBranchActions } from '@crm/core';
import { RuleObject } from 'antd/lib/form';
import { SearchBox } from '../shared';
import { getBranchesList } from '@crm/services.api';
import { capitalizeFirstLetter } from '../../functions';
import Select, { SelectValue } from 'antd/lib/select';

const { Panel } = Collapse;

interface Props {
  form: FormInstance<any>;
  visible: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  saveData: (payload: any) => void;
  isEdit?: boolean;
}

const UserDrawer = ({
  visible,
  onClose,
  form,
  saveData,
  isEdit,
  disabled,
  setDisabled,
}: Props) => {
  const rolesData = useSelector((store: any) => store.role_permissions);
  const branchesLoading = false;
  const dispatch = useDispatch();
  const currentUser = useSelector((store: RootState) => store.auth.user);

  const user = useSelector((store: RootState) => store.users.singleData);
  const userDataLoading = useSelector((store: any) => store.users.loading);

  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const [, setUpdateRole] = useState<string | null>();
  const [branch, setBranch] = useState<string | undefined>();
  const departmentList: any = [];

  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.name === input) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const handleCountryCodeChange = (input: any, country: any) => {
    if (input) {
      setPhone(input?.slice(country?.dialCode?.length));
      setCountryCode(`+${country?.dialCode}`);
    } else {
      setPhone('');
      setCountryCode('');
    }
    setDisabled(false);
  };

  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const validatePhoneNumber = (_: RuleObject, value: string) => {
    if (value && value.replace(/\D/g, '').length < 10) {
      setDisabled(true);
      return Promise.reject('Please enter a valid phone number');
    }
    return Promise.resolve();
  };

  const handleBranchClear = () => {
    form.setFieldsValue({ department_id: null });
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleSubmit = async (data: any) => {
    data.countryCode = countryCode;
    form.submit();
    setDisabled(true);
  };

  useEffect(() => {
    dispatch(officeBranchActions.getAllRequest());
  }, []);

  useEffect(() => {
    if (isEdit) {
      setBranch(user?.branch?.id);
      form.setFieldsValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        address1: user?.address1,
        email: user?.email,
        address2: user?.address2,
        country: user?.country,
        cityOrState: user?.cityOrState,
        phone: user?.phone,
        alternativePhone: user?.alternativePhone,
        secondaryBranches:
          user?.secondaryBranches?.map((branch: IOfficeBranchCRM) => branch.id) ??
          [],
        roleId: user?.role?.id,
        branchId: user?.branch?.id,
        department_id: user?.department,
        timeZone: user?.timeZone,
      });
      handleCountryChange(user?.country);
    }
  }, [isEdit, userDataLoading, user]);

  useEffect(() => {
    if (!isEdit) {
      form.setFieldsValue({
        timeZone: currentUser?.timeZone,
      });
    }
  }, [visible, isEdit]);

  const handleClose = () => {
    onClose();
  };

  if (visible) {
    return (
      <DrawerElem
        title={<div>{isEdit ? USER_EDIT_TITLE : USER_ADD_TITLE}</div>}
        width={DRAWER_WIDTH}
        visible={visible}
        onClose={handleClose}
        placement={USER_ADD_PLACEMENT}
        data-testid="crm-adduserdrawer"
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={handleClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              {isEdit ? (
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {UPDATE_BTN}
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {SAVE_BTN}
                </Button>
              )}
            </Space>
          </div>
        }
      >
        <Spin spinning={rolesData.loading || branchesLoading}>
          <Form
            onFieldsChange={onFieldsChange}
            layout="vertical"
            form={form}
            size={FORM_SIZE}
            onFinish={saveData}
          >
            <div>
              <Row gutter={GUTTER}>
                <Col span="12">
                  <FormItem
                    name="firstName"
                    label={FIRST_NAME_LABEL}
                    rules={[{ required: true, max: 50, type: 'string' }]}
                  >
                    <Input
                      placeholder={FIRST_NAME_PLACEHOLDER}
                      data-testid="crm-adduser-firstname"
                    />
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem
                    name="lastName"
                    label={LAST_NAME_LABEL}
                    rules={[{ required: true, max: 50, type: 'string' }]}
                  >
                    <Input
                      placeholder={LAST_NAME_PLACEHOLDER}
                      data-testid="crm-adduser-lastname"
                    />
                  </FormItem>
                </Col>
                <Divider />
              </Row>
            </div>
            <Collapse
              defaultActiveKey={ADDRESS_INFORMATION}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{ADDRESS_INFORMATION}</span>}
                key={ADDRESS_INFORMATION}
              >
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <FormItem
                      name="address1"
                      label={ADDRESS1_LABEL}
                      rules={[{ required: true, type: 'string', max: 50 }]}
                    >
                      <Input
                        placeholder={ADDRESS1_PLACEHOLDER}
                        data-testid="crm-adduser-addressone"
                      />
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem
                      name="address2"
                      label={ADDRESS2_LABEL}
                      rules={[{ type: 'string', max: 100 }]}
                    >
                      <Input
                        placeholder={ADDRESS2_PLACEHOLDER}
                        data-testid="crm-adduser-addresstwo"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <div id="user_country_list" className="relative">
                      <FormItem
                        name="country"
                        label={COUNTRY_LABEL}
                        rules={[{ required: true, type: 'string', max: 50 }]}
                      >
                        <SearchableSelect
                          onChange={(value: SelectValue) => {
                            if (value) handleCountryChange(value.toString());
                          }}
                          placeholder={COUNTRY_PLACEHOLDER}
                          data-testid="crm-adduser-country"
                          filterOption={handleOptionFilter}
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_country_list')
                          }
                        >
                          {COUNTRIES.map((country) => {
                            return (
                              <Option value={country.name} key={country.code}>
                                {country.name}
                              </Option>
                            );
                          })}
                        </SearchableSelect>
                      </FormItem>
                    </div>
                  </Col>
                  <Col span="12">
                    <FormItem
                      name="cityOrState"
                      label={CITY_OR_STATE_LABEL}
                      rules={[{ required: true, type: 'string', max: 100 }]}
                    >
                      <Input
                        placeholder={CITY_OR_STATE_PLACEHOLDER}
                        data-testid="crm-adduser-city"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider />
            <Collapse
              defaultActiveKey={CONTACT_INFORMATION}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{CONTACT_INFORMATION}</span>}
                key={CONTACT_INFORMATION}
              >
                <FormItem
                  name="email"
                  label={EMAIL_LABEL}
                  validateFirst={true}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: AUTH_MESSAGE.INVALID_EMAIL
                    },
                    {
                      pattern: new RegExp(regex.EMAIL),
                      message: AUTH_MESSAGE.INVALID_EMAIL,
                    },
                  ]}
                >
                  <Input
                    placeholder={EMAIL_PLACEHOLDER}
                    data-testid="crm-adduser-email"
                    disabled={isEdit}
                    autoComplete="off"
                  />
                </FormItem>
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <Form.Item
                      name="phone"
                      data-testid="crm-adduser-phone"
                      label={PHONE_LABEL}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter phone number',
                        },
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                    >
                      <PhoneInput
                        value={phone}
                        placeholder={''}
                        onChange={handleCountryCodeChange}
                        enableLongNumbers={15}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <FormItem
                      data-testid="crm-adduser-altphone"
                      name="alternativePhone"
                      label={ALTERNATIVE_PHONE_LABEL}
                      rules={[
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                    >
                      <PhoneInput
                        value={phone}
                        placeholder={''}
                        onChange={handleCountryCodeChange}
                        enableLongNumbers={15}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider />
            <Collapse
              defaultActiveKey={JOB_INFORMATION}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{JOB_INFORMATION}</span>}
                key={JOB_INFORMATION}
              >
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <div id="user_role" className="relative">
                      <FormItem
                        name="roleId"
                        label={ROLE_LABEL}
                        initialValue={1}
                        data-testid="crm-adduser-role"
                        rules={[{ required: true }]}
                      >
                        <Select
                          onChange={(value: any) => {
                            setUpdateRole(value);
                          }}
                          defaultValue={1}
                          showSearch
                          allowClear
                          optionFilterProp="key"
                          placeholder={ROLE_PLACEHOLDER}
                          defaultActiveFirstOption={false}
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_role')
                          }
                        >
                          <Option value={1} key={'SuperAdmin'} label={'SuperAdmin'}>
                            {"SuperAdmin"}
                          </Option>
                        </Select>
                      </FormItem>
                    </div>
                  </Col>
                  <Col span="12">
                    <FormItem
                      name="branchId"
                      label={LABEL.BRANCH}
                      rules={[{ required: true }]}
                    >
                      <SearchBox
                        fetchQuery={getBranchesList}
                        valueKey='id'
                        labelKey='name'
                        defaultOpen={false}
                        showArrow
                        data-testid="crm-adduser-branch"
                        onChange={(val: string) => {
                          setBranch(val);
                          form.resetFields(['secondaryBranches']);
                        }}
                      />
                    </FormItem>
                  </Col>

                  <Col span="12">
                    <FormItem
                      name="secondaryBranches"
                      label={LABEL.SECONDARY_BRANCHES}
                    >
                      <SearchBox
                        fetchQuery={getBranchesList}
                        filterValues={[branch].filter(Boolean) as string[]}
                        defaultOpen={false}
                        valueKey='id'
                        labelKey='name'
                        allowClear
                        showArrow
                        data-testid="crm-adduser-secbranch"
                        onClear={handleBranchClear}
                        mode='multiple'
                      >
                        {
                          (option) =>
                            <Select.Option value={option.id} label={capitalizeFirstLetter(option.name)}>
                              {option.name}
                            </Select.Option>
                        }
                      </SearchBox>
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <div id="user_department" className="relative">
                      <FormItem
                        name="departmentId"
                        label={LABEL.DEPARTMENT}
                        rules={[{ required: false }]}
                      >
                        <SearchableSelect
                          optionFilter="key"
                          placeholder={PLACEHOLDER.SELECT_DEPARTMENT}
                          data-testid="crm-adduser-department"
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_department')
                          }
                        >
                          {departmentList &&
                            departmentList.map((item: any) => {
                              return (
                                <Option
                                  value={item.department?.id}
                                  key={item.department?.name}
                                >
                                  {item.department?.name}
                                </Option>
                              );
                            })}
                        </SearchableSelect>
                      </FormItem>
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider />
            <Collapse
              defaultActiveKey={CHANGE_TIMEZONE}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{CHANGE_TIMEZONE}</span>}
                key={CHANGE_TIMEZONE}
              >
                <div id="user_timezone" className="relative">
                  <Row gutter={GUTTER}>
                    <Col span="24">
                      <Form.Item
                        label="Timezone"
                        name="timeZone"
                        data-testid="crm-adduser-timezone"
                        rules={[
                          {
                            required: true,
                            message: `${TEXT.TIMEZONE_SELECT}`,
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_timezone')
                          }
                        >
                          {TIMEZONE.map((data, idx) => {
                            return (
                              <Option value={data.utc[0]} key={idx}>
                                {data.text}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Collapse>
          </Form>
        </Spin>
      </DrawerElem>
    );
  } else return <div />;
};

UserDrawer.defaultProps = {
  isView: false,
};

export default UserDrawer;
