import React, { useEffect } from 'react';
import { ProductListComponent } from '@admin/shared';
import { productsActions } from '@admin/core';
import { useDispatch } from 'react-redux';

export const ProductList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productsActions.productFiltersRequestAction());
  }, [dispatch]);

  return <ProductListComponent />;
};
