import React from 'react';
import { TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@moxie/shared';

const Dashboard: React.FC = () => {
  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.DASHBOARD} />
    </div>
  );
};

export default Dashboard;
