import { PlusOutlined } from '@ant-design/icons';
import {
  LABEL,
  PHONE_LABEL,
  TEXT,
  USER_ADD_TITLE,
  USER_TITLE,
} from '@moxie/constants';
import { Button, CrmTable } from '@moxie/shared';
import {
  IUserForm,
} from '@shared-components/models';
import { RootState } from 'apps/crm/src/core/store';
import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IUserColumn, IUserData, IUserTableProps } from './user.model';
import { colSorter } from '../../elements/crm-table/colSorter';
import { UserTableElementCard } from './table-form-name-card';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { useParams } from 'react-router-dom';
import { paginateUsers } from '@crm/services.api';
import { IOfficeBranchCRM } from '@model/branch';

const useColumns = (props: IUserColumn) => {
  const { currentUser, showDrawer, setIsEditMode, actionBar, triggerRefresh, refresh, hideBranch, onClose } = props;
  const columns = [
    {
      title: LABEL.NAME,
      dataIndex: 'name',
      key: "name",
      sorter: () => 0,
      render: (_text: string, row: IUserData, index: number): ReactNode => {
        return (
          <UserTableElementCard
            id={row.id}
            firstName={row.firstName}
            email={row.email}
            lastName={row.lastName}
            userProp={currentUser}
            index={index.toString()}
            data={row}
            showDrawer={showDrawer}
            onClose={onClose}
            setIsEditMode={setIsEditMode}
            actionBar={actionBar}
            triggerRefresh={triggerRefresh}
            refreshTable={refresh}
          />
        )
      },
    },

    {
      title: TEXT.BRANCH,
      key: 'branch',
      dataIndex: 'branch',
      hidden: hideBranch,
      render: (data: IOfficeBranchCRM): ReactNode => {
        return (
          <div className="lead-table__added-from initial_capital">{`${data?.name}`}</div>
        );
      },
    },

    {
      title: TEXT.ADDRESS,
      dataIndex: 'address',
      key: 'address',
      render: (_text: string, row: IUserData): ReactNode =>
      (
        <div className="lead-table__added-from initial_capital">{`${row.address1 ? row.address1 : ''
          } ${row.cityOrState}, ${row.country}`}</div>
      ),
    },
    {
      title: PHONE_LABEL,
      dataIndex: 'phone',
      key: 'phone',
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from">{`${row.phone}`}</div>
      ),
    },

    {
      title: LABEL.EMAIL,
      sorter: () => 0,
      dataIndex: 'email',
      render: (email: string): ReactNode => (
        <div className="lead-table__added-from">{`${email.toLowerCase()}`}</div>
      ),
    },
  ].filter((item) => !item.hidden);

  return React.useMemo(() => {
    return columns.map((c) => colSorter(c as any))
  }, [currentUser])
}

const useDefaultFilter = (name: string) => {

  return useMemo(() => {
    switch (name) {
      case 'active':
        return {
          deactivated: '$eq:false'
        }

      case 'inactive':
        return {
          deactivated: '$eq:true'
        }
      default:
        return {}
    }
  }, [name])

}

const UserTableList: React.FC<IUserTableProps> = ({
  showDrawer,
  refresh,
  actionBar,
  hideBranch,
  setIsEditMode,
  onClose,
  triggerRefresh,
}: IUserTableProps) => {
  const { name } = useParams<{ name: string }>();
  const currentUser = useSelector((store: RootState) => store.auth.user);
  const canAccess = useCanAccess();
  const defaultFilter = useDefaultFilter(name);
  const columns = useColumns({ currentUser, showDrawer, onClose, setIsEditMode, actionBar, triggerRefresh, refresh, hideBranch })

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        key={name}
        tableName={USER_TITLE}
        action={paginateUsers}
        columns={columns}
        size={'small'}
        refresh={refresh}
        filter={defaultFilter}
        tableButtons={
          canAccess('user', 'create') && <Button
            type="primary"
            onClick={() => showDrawer && showDrawer()}
            data-testid="crm-adduser-btn"
          >
            <PlusOutlined />
            {USER_ADD_TITLE}
          </Button>
        }
      />
    </div>
  );
};

export { UserTableList };
