import React from 'react';
import { Auth as AuthModule } from '@shared-components/modules';
import { IAuth, IAuthResponse } from '@shared-components/models';
import { auth } from '@admin/services.api';
import { PROJECT_PORTAL } from '@moxie/constants';

const Auth: React.FC = () => {
  const handleSubmit = (data: IAuth): Promise<IAuthResponse> => {
    return auth(data);
  };
  return (
    <div className="login-layout">
      <AuthModule onSubmit={handleSubmit} portal={PROJECT_PORTAL.ADMIN_TITLE} />
    </div>
  );
};

export default Auth;
