import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { productsActions } from '@admin/core';
import { ProductDetailComponent } from 'libs/shared/src/admin-modules';

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productsActions.getProductRequest(id));

    return () => {
      dispatch(productsActions.getProductClear());
    };
  }, [id]);

  return <ProductDetailComponent />;
};
export { ProductDetail };
