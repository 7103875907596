import {
  BankOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import {
  IELTS_SCORE,
  INDICATIVE_FEE,
  URL_PRODUCT_DETAIL_LINK,
  VIEW_DETAILS,
  regex,
} from '@moxie/constants';
import { capitalizeAllLetter, capitalizeFirstLetter } from '@moxie/shared';
import { Avatar, Button, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { ProductMenu } from './product-menu';
import Text from 'antd/lib/typography/Text';
import { ProductIntakeComponent } from './product-intake';
import { ProductView } from '../product.model';

interface Props {
  product: ProductView;
  setIsOpen: (val: boolean) => void;
  setIsEdit: (val: boolean) => void;
  setProductId: (val: string) => void;
  deleteProduct: (productId: string) => void;
}
const ProductsCard: React.FC<Props> = ({
  product,
  setIsOpen,
  setIsEdit,
  setProductId,
  deleteProduct,
}) => {
  const screenWidth = window.innerWidth;
  const displayBranch = product?.branches?.slice(0, 2);
  const additionalBranchesNumber = product?.branches?.length - 2;
  const additionalBranches = product?.branches?.slice(2);

  const showDurations = () => {
    return (
      <>
        <p>
          <span>
            {product?.productDurationYear
              ? `${product?.productDurationYear} yr`
              : null}{' '}
          </span>
          <span>
            {product?.productDurationMonth
              ? `${product?.productDurationMonth} mo`
              : null}{' '}
          </span>
          <span>
            {product?.productDurationWeek
              ? `${product?.productDurationWeek} wk`
              : null}{' '}
          </span>
        </p>
      </>
    );
  };

  return (
    <>
      <section className="margin-bottom-2 ">
        <Row className="page-header__menu">
          <Col>
            <Typography.Title
              level={4}
              className="text-primary ellipsis-text"
              ellipsis={
                String(product?.productName).length >= 40
                  ? { tooltip: capitalizeFirstLetter(product?.productName) }
                  : false
              }
            >
              {capitalizeFirstLetter(product?.productName)}
            </Typography.Title>
          </Col>
          <Col>
            <ProductMenu
              setIsOpen={setIsOpen}
              setIsEdit={setIsEdit}
              productId={product.productId}
              deleteProduct={() => deleteProduct(product.productId)}
              setProductId={() => setProductId(product.productId)}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            <div className="profile__info__item profile__info__user">
              <Avatar size="default" className="margin-right-2">
                <BankOutlined className="product-institution-icon" />
              </Avatar>
              <div>
                {screenWidth >= 1200 && screenWidth < 1600 ? (
                  String(product?.institutionName).length >= 25 ? (
                    <Tooltip title={product?.institutionName}>
                      <Text className="font-bold institution-ellipsis" ellipsis>
                        {capitalizeFirstLetter(product?.institutionName) ??
                          'N/A'}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="font-bold">
                      {capitalizeFirstLetter(product?.institutionName) ??
                        'N/A'}
                    </Text>
                  )
                ) : screenWidth >= 1600 &&
                  String(product?.institutionName).length >= 30 ? (
                  <Tooltip
                    title={capitalizeFirstLetter(product?.institutionName)}
                  >
                    <Text className="font-bold institution-ellipsis" ellipsis>
                      {capitalizeFirstLetter(product?.institutionName) ??
                        'N/A'}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text className="font-bold">
                    {capitalizeFirstLetter(product?.institutionName) ?? 'N/A'}
                  </Text>
                )}

                <div className="products-branches">
                  <EnvironmentOutlined className="location-icon" />
                  {product?.branches
                    ? displayBranch?.map((branch: string, i: number) => (
                      <React.Fragment key={i}>
                        <>
                          {i > 0 && ', '}
                          {branch?.length > 17 ? (
                            <Tooltip title={capitalizeFirstLetter(branch)}>
                              {branch.slice(0, 17) + '...'}
                            </Tooltip>
                          ) : (
                            capitalizeFirstLetter(branch)
                          )}
                          &nbsp;
                        </>
                      </React.Fragment>
                    ))
                    : 'N/A'}
                  {additionalBranchesNumber > 0 && (
                    <Tooltip
                      title={
                        <p className="initial_capital">
                          {additionalBranches?.join(', ')}
                        </p>
                      }
                    >
                      <span>
                        {additionalBranchesNumber >= 1
                          ? `+${additionalBranchesNumber} More`
                          : ''}
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <Row className="margin-left-3">
              <Col
                span={12}
                className="product-discipline margin-top-2 margin-bottom-1 margin-right-3 margin-left-2"
              >
                <p className="font-bold initial_capital">
                  {product?.productSubType ?? 'N/A'}
                </p>
                <p className="initial_capital">
                  {product?.productType ?? 'N/A'}
                </p>
              </Col>
              <Col span={6}>
                <p className="margin-top-2 font-bold">{IELTS_SCORE}</p>
                <p>{product?.overallScore ?? 'N/A'}</p>
              </Col>
            </Row>
          </Col>
          <Col span={6} className="border-right">
            <Row>
              <Col className="margin-top-2" offset={4}>
                <div className="product-type">
                  <CalendarOutlined className="product-type-icons" />
                  <ProductIntakeComponent intakes={product?.intakes} />
                </div>
                <div className="product-type">
                  <HistoryOutlined className="product-type-icons" />
                  {product.productDurationYear ||
                    product.productDurationMonth ||
                    product.productDurationWeek
                    ? showDurations()
                    : 'N/A'}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            span={4}
            className="border-left ant-card-body-padding-1 border-right"
          >
            <div className="product-fee">
              <Typography.Text className="indicative-fee">
                {INDICATIVE_FEE}
              </Typography.Text>
              <Typography.Title level={4} className="margin-0">
                {product?.fees && parseFloat(product?.fees) !== 0 ? (
                  <div>
                    {product?.fees
                      ?.toString()
                      .replace(new RegExp(regex.FEES), ',')}{' '}
                    {capitalizeAllLetter(product?.currency)}
                  </div>
                ) : (
                  'Unavailable'
                )}
              </Typography.Title>
            </div>
          </Col>
          <Col span={4}>
            <div className="product-icons"></div>
            <div>
              <a href={`${URL_PRODUCT_DETAIL_LINK}${product.productId}`} target="_blank" rel="noreferrer">
                <Button className="full-width margin-top-3 view-details-button">
                  {VIEW_DETAILS}
                </Button>
              </a>
            </div>
          </Col>
        </Row>
        <Row />
        <div className="border-bottom margin-bottom-2 margin-top-2" />
      </section>
    </>
  );
};

export default ProductsCard;
