import {
  AppstoreOutlined,
  EditOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { userActions } from '@crm/core';
import {
  ACTIVATE,
  CONFIRM_MESSAGE,
  DEACTIVATE,
  EDIT_BTN,
  URL_USER_DETAIL_LINK,
} from '@moxie/constants';
import { ITableNameCardProps, IUser } from '@shared-components/models';
import { Menu } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { popConfirm } from '../../shared';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { UserNameCard } from '../shared';

const userMenu = (
  deactivated: boolean,
  {
    onEdit,
    onDeactivate,
    onActivate,
  }:
    {
      onEdit: () => void;
      onDeactivate: () => void;
      onActivate: () => void;
    },

  data: IUser | any
) => {
  return (
    <Menu>
      {!data.deactivated && (
        <Menu.Item key={1} data-testid="crm-edituser-menu" onClick={onEdit} icon={<EditOutlined />}>
          {EDIT_BTN}
        </Menu.Item>
      )}
      {data?.deactivated ? (
        <Menu.Item key={2} data-testid="crm-activateuser-menu" onClick={onActivate} icon={<AppstoreOutlined />}>
          {ACTIVATE}
        </Menu.Item>
      ) : (
        <Menu.Item
          key={2}
          data-testid="crm-deactivateuser-menu"
          onClick={onDeactivate}
          danger
          icon={<StopOutlined />}
        >
          {DEACTIVATE}
        </Menu.Item>
      )}
    </Menu>
  );
};

export const UserTableElementCard: React.FC<ITableNameCardProps | any> = ({
  firstName,
  lastName,
  email,
  index,
  id,
  data,
  showDrawer,
  setIsEditMode,
  triggerRefresh,
}: ITableNameCardProps | any) => {
  const dispatch = useDispatch();
  const canAccess = useCanAccess();
  const handleEdit = () => {
    dispatch(userActions.fetchUser(id));
    showDrawer(true);
    setIsEditMode(true);
  };

  const onDeactivate = async () => {
    if (data?.deactivated === false) {
      dispatch(
        userActions.deactivateUserRequest(id, triggerRefresh)
      );
    }
  };

  const handleDeactivate = () => {
    popConfirm(
      DEACTIVATE,
      `${CONFIRM_MESSAGE.DEACTIVATE_USER} ${firstName} ${lastName}?`,
      onDeactivate,
      true
    );
  };

  const onActivate = async () => {
    if (data?.deactivated === true)
      dispatch(
        userActions.activateUserRequest(id, triggerRefresh)
      );

  };

  const handleActivate = async () => {
    popConfirm(
      ACTIVATE,
      `${CONFIRM_MESSAGE.ACTIVATE_USER} ${firstName} ${lastName}?`,
      onActivate
    );
  };

  const menuActions = {
    onEdit: handleEdit,
    onDeactivate: handleDeactivate,
    onActivate: handleActivate,
  };
  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      id={id}
      email={email}
      link={canAccess('user', 'read') ? `${URL_USER_DETAIL_LINK}${id}` : undefined}
      actionBar={true}
      index={index}
      menu={canAccess('user', 'update') ? userMenu(data?.deactivated || false, menuActions, data) : null}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};

export const TableUserNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  index,
  email,
  id,
  handleEdit,
  actionBar,
}: ITableNameCardProps | any) => {
  return (
    <UserNameCard
      index={index}
      id={id}
      firstName={firstName}
      lastName={lastName}
      email={email}
      link={`${URL_USER_DETAIL_LINK}${id}`}
      actionBar={actionBar}
      menu={userMenu}
      showEmailAsLink={true}
      showMailIcon={true}
      handleEdit={handleEdit}
    />
  );
};
