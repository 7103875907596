import { getUserPermissionsList } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';


export const useFetchPermissionsList = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['fetch-user-permissions-list'],
    queryFn: () => getUserPermissionsList(),
    onError: () => {
      errorNotificationHandler('Error fetching user permissions list.');
    },
  });

  return {
    permissionsList: data?.data ?? [],
    permissionsListLoading: isLoading,
  }
}
