import { errorHandler } from '@moxie/utils';
import { useEffect, useState } from 'react';
import { decode } from 'qss';
import { PaginationMeta, CrmResponse } from '@model/api-response.model';
import { useHistory, useLocation } from 'react-router';
import dot from 'dot-object';
import pickBy from 'lodash/pickBy';
import { CancelToken } from 'axios';

interface Pagination {
  page: number;
  search?: string;
  sortBy?: string;
  limit?: number
}

interface ICrmPagination<T extends unknown> {
  // eslint-disable-next-line no-unused-vars
  action: (params: Record<string, unknown>, token?: CancelToken) => Promise<CrmResponse<T>>;
  filter?: Record<string, unknown> | undefined;
  type?: string;
  token?: CancelToken;
}

let didInit = false;
function usePaginationCRM<T extends unknown>({
  action,
  filter,
  type,
  token,
}: ICrmPagination<T>) {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    search: undefined,
    sortBy: undefined,
    limit: 10
  });

  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: [],
    totalItems: 0,
    totalPages: 0,
  })

  useEffect(() => {
    const params = decode<Pagination>(location.search.replace('?', ''));
    setPagination(prev => ({
      ...prev,
      page: params.page ?? 1,
      limit: params.limit ?? 10,
      search: params.search ?? undefined,
      sortBy: params.sortBy ?? undefined
    }))
  }, [])

  useEffect(() => {
    executeAction()
  }, [pagination]);

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      page: 1,
      limit: 10,
      search: undefined,
      sortBy: undefined
    }))
  }, [filter])

  const executeAction = async () => {

    if (!didInit) {
      didInit = true;
      return;
    }
    setData([]);
    setLoading(true);

    try {
      const params = {
        ...pagination,
        ...((filter && Object.keys(filter).length) && dot.dot({ filter })),
        type,
      }
      const response = await action(params, token);
      setData(response.data.data);
      setPaginationMeta(response.data.meta);
      const url = new URLSearchParams(pickBy(params, v => v !== undefined))
      history.replace({ search: url.toString() })
    } catch (err: any) {
      setData([]);
      if (err?.response?.status !== 404) errorHandler(err);
    }
    finally {
      setLoading(false);

    }
  };

  const refetchRecords = () => executeAction()

  return {
    data: data as readonly Record<string, unknown>[],
    paginationMeta,
    isLoading,
    setPagination,
    refetchRecords,
    pagination,
    refetch: executeAction
  }
}

export default usePaginationCRM;
