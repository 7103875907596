import { IAction, IOfficeVisitCrm } from '@shared-components/models';
import * as actionType from './office-visit.constant';

export const addOfficeVisitRequest = (
  payload: IOfficeVisitCrm,
  triggerRefresh?: () => void
): IAction<IOfficeVisitCrm> => ({
  type: actionType.ADD_OFFICE_VISIT_REQUEST,
  payload,
  triggerRefresh,
});

export const addOfficeVisitSuccess = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.ADD_OFFICE_VISIT_SUCCESS,
});

export const addOfficeVisitFailure = (err: any): IAction<IOfficeVisitCrm> => ({
  type: actionType.ADD_OFFICE_VISIT_FAILURE,
  payload: err,
});

export const getOfficeVisitRequest = (id: string): IAction<unknown> => ({
  type: actionType.GET_OFFICE_VISIT_REQUEST,
  id,
});

export const getOfficeVisitSuccess = (
  payload: IOfficeVisitCrm
): IAction<IOfficeVisitCrm> => ({
  type: actionType.GET_OFFICE_VISIT_SUCCESS,
  payload,
});

export const getOfficeVisitFailure = (err: any): IAction<IOfficeVisitCrm> => ({
  type: actionType.GET_OFFICE_VISIT_FAILURE,
  payload: err,
});

export const updateOfficeVisitRequest = (
  id: string,
  payload: Partial<IOfficeVisitCrm>,
  triggerRefresh?: () => void
): IAction<Partial<IOfficeVisitCrm>> => ({
  type: actionType.UPDATE_OFFICE_VISIT_REQUEST,
  id,
  payload,
  triggerRefresh,
});

export const updateOfficeVisitSuccess = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.UPDATE_OFFICE_VISIT_SUCCESS,
});

export const updateOfficeVisitFailure = (err: any): IAction<IOfficeVisitCrm> => ({
  type: actionType.UPDATE_OFFICE_VISIT_FAILURE,
  payload: err,
});

export const clearOfficeVisitSingleData = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.CLEAR_OFFICE_VISIT_DATA,
});

export const openOfficeVisitDrawer = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.OPEN_OFFICE_VISIT_DRAWER,
});

export const closeOfficeVisitDrawer = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.CLOSE_OFFICE_VISIT_DRAWER,
});

export const openOfficeVisitDetailsDrawer = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.OPEN_OFFICE_VISIT_DETAILS_DRAWER,
});

export const closeOfficeVisitDetailsDrawer = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.CLOSE_OFFICE_VISIT_DETAILS_DRAWER,
});

export const officeVisitTableRefresh = (): IAction<IOfficeVisitCrm> => ({
  type: actionType.OFFICE_VISIT_TABLE_REFRESH,
});
