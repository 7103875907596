export const regex = {
  EMAIL: /^\s*(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
  ONLY_NUMBER: /^\s*[0-9]+\s*$/,
  NUMBER_WITH_DASH_AND_BLANK: /^\s*[0-9- ]+\s*$/,
  ONLY_STRING: /^\s*[a-zA-Z_ ]+\s*$/,
  POSTAL_CODE: /^\d{5}$|^\d{5}-\d{4}$/,
  ALPHA_NUMERIC: /^\s*[a-zA-Z0-9]+\s*$/,
  ALPHA_NUMERIC_WITH_SPACE: /^[\w\-\s]+$/,
  FILE_NAME: /^[a-zA-z0-9_\s]+$/,
  WEBSITE: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/,
  PHONE_NUMBER: /^\d{1,10}$/,
  FEES: /\B(?=(\d{3})+(?!\d))/g,
  FEES_INPUT: /^\d{0,8}(\.\d{1,2})?$/,
  UUID: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
  COMMENTS: /<(.|\n)*?>/,
  SANITIZE_QUERY: /[#-.]|[[-^]|[?|{}]/g,
  DATE: /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/i,
  ISO_STRING: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|Z)?$/,
  GLOBAL_ACCEPTED_LAST_NAME: /^[A-Za-z\s'-]+$/,
  YYYY_MM_DD: /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  COUNTRY_CODE: /^[A-Z]{1,3}$/,
  TEN_TO_11_DIGIT_NUM: /^\s*\d{10,11}\s*$/,
  DD_MM_YYYY: /^(?:(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(?!0000)\d{4})$|(?:(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-(?!0000)\d{4})$/,
  STRING_WITH_SPECIAL_CHARS: /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>_+=\-[\]\\/\s]*$/,
  LEAD_PHONE_NUMBER: /^(?!\+)\d{8,14}$/,
};
