import React from 'react';
import { Route } from 'react-router-dom';
import DashboardProfile from './dashboard-profile/dashboard-profile';
import GeneralSettings from './dashboard-settings/general-settings';
import Dashboard from './dashboard-content';

const DashboardRoute: React.FC = () => {
  return (
    <>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/dashboard/settings" component={GeneralSettings} />
      <Route path="/dashboard/profile" component={DashboardProfile} />
    </>
  );
};

export default DashboardRoute;
