import React, { useEffect } from 'react';
import ProLayout from '@ant-design/pro-layout';
import { NavLink } from 'react-router-dom';
import { IDashboardLayoutProps } from '@shared-components/models';
import { AdminLogoIconLight } from '@shared-components/elements';
import { CRM_COOKIE_ACCESS, PROJECT_PORTAL } from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { useAppSelector, themeActions, authActions } from '@crm/core';
import Cookies from 'js-cookie';
import DashboardHeader from './dashboard-header';
import { routes } from './routes';

const CrmDashboardLayout: React.FC<IDashboardLayoutProps> = (
  props: IDashboardLayoutProps
) => {
  const { children, className } = props;
  const dispatch = useDispatch();
  const collapsed = useAppSelector((store) => store.theme.navCollapsed);

  const handleNavbarToggle = (bool: boolean) => dispatch(themeActions.toggleNavbar(bool));
  useEffect(() => {
    if (!Cookies.get(CRM_COOKIE_ACCESS)) {
      return;
    }
    dispatch(authActions.isAuthenticated());
  }, [dispatch]);

  return (
    <ProLayout
      {...routes}
      {...props}
      siderWidth={200}
      className={`dashboard-layout ${className}`}
      navTheme="dark"
      headerHeight={60}
      collapsed={collapsed}
      collapsedButtonRender={false}
      logo={() => <AdminLogoIconLight />}
      title={PROJECT_PORTAL.CRM}
      menuItemRender={(item, dom) => (
        <NavLink to={`${item.path}`}>{dom}</NavLink>
      )}
      fixSiderbar={true}
      fixedHeader={true}
      onCollapse={handleNavbarToggle}
      headerContentRender={() => (
        <DashboardHeader
          setCollapsed={handleNavbarToggle}
          collapsed={collapsed}
        />
      )}
    >
      {children}
    </ProLayout>
  );
};

export { CrmDashboardLayout };
