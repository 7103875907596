import React, { useState } from 'react';
import { PAGE_HEADER_MENU_CRM_USER, USER_FORM_RESPONSE_MESSAGE } from '@moxie/constants';
import { IUserFormAdd } from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UserDrawer from './user-drawer';
import { UserTableList } from './user-table-list';
import { PageHeaderMenu, crmErrorHandler } from '../shared';
import { RootState } from '@crm/src/core/store';
import { useUserMutation, useUserUpdate } from '@crm/libs/hooks/users';
import { successNotificationHandler } from '../../functions';


const UserTable = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [personalDetailsForm] = useForm();
  const [disabled, setDisabled] = useState(true);
  const [, setFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [, setInitialData] = useState<IUserFormAdd>();
  const [refreshTable, setRefreshTable] = useState(true);
  const userData = useSelector((store: RootState) => store.users.singleData);
  const { name }: { name: string } = useParams();

  const adduser = useUserMutation();
  const updateUser = useUserUpdate();

  const triggerRefresh = () => setRefreshTable((prev) => !prev);
  const handleDrawerClose = () => {
    setFormData({});
    setDrawerVisible(false);
    personalDetailsForm.resetFields();
    setIsEditMode(false);
    setDisabled(true);
  };

  const showDrawer = async () => {
    setDrawerVisible(true);
    setIsEditMode(false);
    setDisabled(true);
  };

  const handleSubmit = async (data: any) => {
    const secondaryBranches = data.secondaryBranches?.map((item: string) => ({
      id: item,
    }));
    data = {
      ...data,
      email: data.email.toLowerCase(),
    }
    delete data?.secondaryBranches;
    if (isEditMode) {
      const updateData = {
        id: userData.id,
        ...data,
        secondaryBranches: secondaryBranches ?? []
      }
      await updateUser.mutateAsync(updateData, {
        onSuccess: () => {
          handleDrawerClose();
          successNotificationHandler(USER_FORM_RESPONSE_MESSAGE.USER_UPDATED);
          triggerRefresh();
        },
        onError: (error) => {
          crmErrorHandler(error)
        }
      })
    } else {
      const addableData = {
        ...data,
        secondaryBranches
      }
      adduser.mutate(addableData, {
        onSuccess: () => {
          handleDrawerClose();
          successNotificationHandler(USER_FORM_RESPONSE_MESSAGE.USER_ADDED);
          triggerRefresh();
        },
        onError: (error) => {
          crmErrorHandler(error);
        }
      });
    }
  };
  return (
    <div className="full-height">
      <PageHeaderMenu menu={PAGE_HEADER_MENU_CRM_USER} defaultActiveKey={name}>
        <UserDrawer
          visible={drawerVisible}
          setDisabled={setDisabled}
          disabled={disabled}
          onClose={handleDrawerClose}
          form={personalDetailsForm}
          saveData={handleSubmit}
          isEdit={isEditMode}
        />
      </PageHeaderMenu>
      <UserTableList
        actionBar={true}
        showDrawer={showDrawer}
        refresh={refreshTable}
        hideBranch={false}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
};

export default UserTable;
