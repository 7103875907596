import { RcFile } from "antd/es/upload";
import axiosInstance from "./axios";
import crmAxios from "./crm-api-axios";
import { CrmResponse } from "@model/api-response.model";
import { EnquiryForm, EnquiryFormFields, ObjectProps } from "@model/contact-form";
import { omit } from 'lodash';
import { API_URL } from "@moxie/constants";
export const uploadFormImage = async (fieldName: string, file: RcFile) => {
  const formData = new FormData();
  formData.append(fieldName, file)
  const { data } = await axiosInstance.post('/crm/contact-form/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return data;
}

const transformPayloadToFormData = (payload: EnquiryFormFields) => {
  omit(payload, 'preview');
  const formData = Object.keys(payload).reduce((formData, fieldName) => {
    const val = payload[fieldName as keyof EnquiryFormFields]
    switch (typeof val) {
      case 'object': {
        const obj = payload[fieldName as ObjectProps]
        if (obj) {
          Object.entries(obj).reduce((form, [key, value]) => {
            if (typeof value === 'string') {
              form.append(`${fieldName}[${key}]`, value)

            }
            else {
              form.append(`${fieldName}[${key}]`, JSON.stringify(value))

            }
            return form
          }, formData);
        }
        if (obj === null) {
          formData.append(`${fieldName}`, JSON.stringify(null))

        }
        break;
      }
      case 'string':
        formData.append(fieldName, val as string)
        break;

      case 'boolean':
        formData.append(fieldName, JSON.stringify(val))
        break;

      default:
        break;
    }
    if (val instanceof Blob) {
      formData.append('file', val)
    }
    return formData;
  }, new FormData());
  return formData;
}
export const addEnquiryFormData = async (payload: EnquiryFormFields) => {
  const formData = transformPayloadToFormData(payload);

  const { data } = await crmAxios.post('/v1/contact-forms', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
}
export const getEnquiryForms = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<EnquiryForm>>(`/v1/contact-forms`, {
    params
  });
  return data;
}
export const updateEnquiryForms = async (payload: EnquiryFormFields) => {

  const formData = transformPayloadToFormData(payload);

  const { data } = await crmAxios.put(`/v1/contact-forms/${payload.id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
}
export const getEnquiryFormById = async (id: string) => {
  const { data } = await crmAxios.get(`/v1/contact-forms/${id}`);
  return data;
}

export const getImageSignedUrl = async (mediaId: string) => {
  const { data } = await crmAxios.get<{ data: string }>(`/v1/contact-forms/media/${mediaId}`)
  return data.data;
}
export const deleteEnquiryForm = async (id: string) => {
  const { data } = await crmAxios.delete(`/v1/contact-forms/${id}`);
  return data;
}

export const getContactEnquiries = async (contactId: string, page: number, limit: number) => {
  return (await crmAxios.get(API_URL.CRM_API_CONTACT_ENQUIRIES, {
    params: {
      'filter.contactId': contactId,
      page,
      limit
    }
  }))?.data;
}
