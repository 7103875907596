import { crmAxios } from "@crm/services.api";
import { ContactEnquiryCount } from "@model/contact-form";
import { Response } from "@model/data.model";
import { API_URL } from "@moxie/constants";
import { useQuery } from "@tanstack/react-query";

const useContactEnquiryCount = () => {
    const { data, refetch } = useQuery({
        queryKey: ['contact-enquiry-count'],
        queryFn: () => crmAxios.get<Response<ContactEnquiryCount>>(`${API_URL.CRM_API_CONTACT_ENQUIRIES}/count`)
    });

    return {
        newCount: Number(data?.data.data?.new) ?? 0,
        existingCount : Number(data?.data.data?.existing) ?? 0,
        refetch
    }
}

export default useContactEnquiryCount;
