import React from 'react';
import { ApplicationDetailComponent } from '@admin/shared';

const ApplicationDetail = () => {
  return (
    <div className="full-height">
      <ApplicationDetailComponent />
    </div>
  );
};

export { ApplicationDetail };
