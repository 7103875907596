import Text from 'antd/lib/typography/Text';
import { IContactCrm } from '@model/crm/contact.model';

import React, { useEffect, useMemo, useState } from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { AutoComplete, Avatar, Col, Row, Select, Space, Spin, Tag, Tooltip } from 'antd';
import { CONTACT_RESPONSE_MESSAGE, TEXT, URL_CONTACT_DETAIL } from '@moxie/constants';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';
import { canViewContactStatus } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { CrmResponse } from '@model/api-response.model';
import type { AutoCompleteProps } from 'antd';

interface SearchBarProps extends AutoCompleteProps {
  fetchOptions: (val: string, page? : number) => Promise<CrmResponse<IContactCrm>>;
  debounceTimeout?: number;
}

function SearchBar({ fetchOptions, debounceTimeout = 800, ...props }: SearchBarProps) {
  const [options, setOptions] = useState<IContactCrm[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const fetchRef = React.useRef(0);
  const history = useHistory();

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setIsFetching(true);
      fetchOptions(value).then((res: CrmResponse<IContactCrm>) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(res?.data?.data || []);
        setIsFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleSelect = async (value: string) => {
    setSelectedOption('');
    if (value) {
      const viewStatus = await canViewContactStatus(value);
      if (viewStatus) {
        setSelectedOption(value);
        history.push(`${URL_CONTACT_DETAIL}${value}/applications`);
      } else {
        errorNotificationHandler(CONTACT_RESPONSE_MESSAGE.NOT_AUTHORIZED_TO_VIEW);
      }
    }
    return;
  };

  const handleScroll = (e:React.UIEvent<HTMLDivElement>) => {
    const { scrollTop , scrollHeight, clientHeight } = e.currentTarget;
    if(scrollTop + clientHeight >= scrollHeight && hasMore){
      loadMore();
    }
  }

  const loadMore = () => {
    setIsFetching(true);
    fetchOptions(searchValue, currentPage + 1).then((res: CrmResponse<IContactCrm>) => {
      setOptions(prev => [...prev, ...(res?.data?.data || [])]);
      setIsFetching(false);
      setCurrentPage(currentPage + 1);
      if (!res?.data?.data?.length) {
        setHasMore(false);
      }
    });
  };

  useEffect(() => {
    if (searchValue) {
      debounceFetcher(searchValue);
    }
  }, [searchValue , debounceFetcher]);

  const dataSource = options?.map((item: IContactCrm) => {
    return (
      <Select.Option
        key={item?.id}
        value={item?.id}
      >
        <SearchBarProfileCard
          contact={item}
        />
      </Select.Option>
    );
  })

  return (
    <AutoComplete
      showSearch
      filterOption={false}
      onSelect={handleSelect}
      value={selectedOption}
      onSearch={setSearchValue}
      autoClearSearchValue
      dataSource={dataSource}
      notFoundContent={
        !options?.length && !isFetching ?
          <div className="dashboard-header__search-more">
            <Space direction="horizontal">
              <Text>{TEXT.NO_CONTACTS_FOUND}</Text>
            </Space>
          </div>
          :
          isFetching ? <Spin /> : null
      }
      onPopupScroll={handleScroll}
      {...props}
    />
  );
}
export { SearchBar };

interface SearchBarProfileCardProps {
  contact: IContactCrm
}
export const SearchBarProfileCard: React.FC<SearchBarProfileCardProps> = ({
  contact
}) => {
  const {
    internalId: id,
    firstName,
    lastName,
    email,
    countryCode,
    phone,
    status,
    branch,
    archived: archivedContact
  } = contact;

  const branchName = branch?.name;
  return (
    <>
      <Row
        className="contact-name-card padding-top-1 padding-bottom-1"
        justify="start"
      >
        <Col span={2}>
          <div className="align-items-center user-name-card__avatar">
            <Avatar size={'large'} className="initial_capital">
              {firstName?.substring(0, 1).toUpperCase()}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        </Col>
        <Col span={21}>
          <Row justify="start" className="padding-bottom-1 margin-left-1">
            {status ? (
              <>
                <Col md={19} lg={18} className="contact-profile-name">
                  <Text className="text-primary-color initial_capital">
                    {`${firstName} ${lastName}`}
                  </Text>
                  <Text className="contact-profile-text margin-left-1">|</Text>
                  <Text className="profile__title margin-left-1">{id}</Text>
                </Col>
                <Col md={5} lg={6} className="status-tag-position">
                  {archivedContact === true ? (
                    <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
                  ) : (
                    <Tag>{status}</Tag>
                  )}
                </Col>
              </>
            ) : archivedContact === true ? (
              <>
                <Col span={21} className="contact-profile-name">
                  <Text className="text-primary-color initial_capital">
                    {`${firstName} ${lastName}`}
                  </Text>
                  <Text className="contact-profile-text margin-left-1">|</Text>
                  <Text className="profile__title margin-left-1">{id}</Text>
                </Col>
                <Col span={3}>
                  <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
                </Col>
              </>
            ) : (
              '-'
            )}
          </Row>
          <Row justify="space-between" className="margin-left-1">
            <Col md={19} lg={18}>
              <Row>
                <Col>
                  <MailOutlined className="table-mail-icon" />
                </Col>
                <Col>
                  {email.length > 25 ? (
                    <Tooltip title={email} placement="left">
                      <Text className="contact-profile-email" ellipsis={true}>
                        {email?.toLocaleLowerCase()}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="contact-profile-text">
                      {email?.toLocaleLowerCase()}
                    </Text>
                  )}
                </Col>
                <Col>
                  <Text className="contact-profile-text margin-left-1">|</Text>
                  <PhoneOutlined
                    rotate={90}
                    className="table-mail-icon margin-left-1"
                  />
                  <Text className="contact-profile-text">
                    {countryCode}&nbsp;
                    {phone}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col md={5} lg={6} className="profile-branch-position">
              <Text className="contact-profile-title">Branch:</Text>
              {branchName && branchName.length > 15 ? (
                <Tooltip title={branchName} placement="bottom">
                  <Text
                    className="contact-profile-branch padding-left-1"
                    ellipsis={true}
                  >
                    {branchName}
                  </Text>
                </Tooltip>
              ) : (
                <Text className="contact-profile-text padding-left-1">
                  {branchName}
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
