import React, { useState } from 'react';
import { Col, Form, Row, Select, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  ADD_BTN,
  CHECKLISTS_REQUIRED,
  GUTTER,
  PLACEHOLDER,
} from '@moxie/constants';
import { CloseOutlined } from '@ant-design/icons';
import { Button, SearchableSelect } from '@shared-components/elements';
import { useAppSelector, workflowActions } from '@crm/core';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { RootState } from 'apps/crm/src/core/store';
import { addWfStageChecklist } from 'apps/crm/src/libs/services.api/lib/workflow.api';
import { Key } from 'rc-select/lib/interface/generator';
import { IChecklistData, IStageCheckList } from '../workflow.model';
import axios from 'axios';
import { errorNotificationHandler, warnNotificationHandler } from 'libs/shared/src/functions';

const WorkflowStageChecklists = ({ stageId }: { stageId: string; }) => {
  const [form] = useForm();
  const { Option, OptGroup } = Select;
  const dispatch = useDispatch();
  const allChecklists: IChecklistData[] = useAppSelector(
    (state) => state.workflows.allChecklists
  );

  const { checklists, loading }: { checklists: IStageCheckList[], loading?: boolean } = useAppSelector((state: RootState) => ({
    checklists: state.workflows.checklists,
    loading: state.workflows.checklists_loading,
  }));


  const [submitted, setSubmitted] = useState(false);

  const allChecklistArray = React.useMemo(() => {
    if (checklists !== undefined) {
      const stagedCheckListIds = checklists.map(c => c.checklist.id);
      const formatData = allChecklists.filter(item => !stagedCheckListIds.includes(item.id));
      return _.groupBy(formatData, 'type')
    }
    return {}
  }, [checklists, loading, allChecklists])

  const handlePostChecklist = async (data: Record<string, number[]>) => {
    try {
      setSubmitted(true);
      await addWfStageChecklist({ stageId, checklists: data.checklists });
      dispatch(workflowActions.getWfStageChecklists(stageId));
      form.resetFields();
    } catch (error) {

      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          warnNotificationHandler(error.response.data.message)
          return
        }
        errorNotificationHandler(error.response?.data.message)
      }
    }
    finally {
      setSubmitted(false);
    }
  };

  const handleDeleteChecklist = (id: string) => {
    dispatch(workflowActions.deleteStageChecklistRequest(id, stageId));
  };

  return (
    <div className="margin-top-2">
      <Spin spinning={loading || submitted}>
        <Form form={form} layout="vertical" onFinish={handlePostChecklist}>
          <Row gutter={GUTTER}>
            <Col span={21}>
              <Form.Item
                name="checklists"
                rules={[{ required: true, message: CHECKLISTS_REQUIRED }]}
              >
                <SearchableSelect
                  placeholder={PLACEHOLDER.MULTIPLE_CHECKLISTS}
                  mode="multiple"
                >
                  {Object.entries(allChecklistArray).map(
                    (item: any, index: number) => (
                      <OptGroup label={item[0]} key={index}>
                        {item[1]?.map((pos: { id: Key; name: string }) => {
                          return (
                            <Option
                              value={pos?.id}
                              key={pos?.name}
                              className="document-option-type"
                            >
                              {pos.name}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    )
                  )}
                </SearchableSelect>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button htmlType="submit" type="primary">
                {ADD_BTN}
              </Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={[16, 16]} className="padding-x1 margin-top-2">
          {checklists &&
            checklists.map((stageChecklist) => {
              return (
                <Col
                  key={stageChecklist.id}
                  span={7}
                  className="checklist-items"
                >
                  <>
                    <Col>
                      <Typography.Text strong>
                        {stageChecklist.checklist.name}
                      </Typography.Text>
                    </Col>
                    <Col
                      onClick={() => handleDeleteChecklist(stageChecklist.id)}
                    >
                      <CloseOutlined />
                    </Col>
                  </>
                </Col>
              );
            })}
        </Row>
      </Spin>
    </div>
  );
};
export { WorkflowStageChecklists };
