/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import AntSelect from 'antd/lib/select';
import {
  ADD_MESSAGE,
  FORM_SIZE,
  GUTTER,
  LABEL,
  VALIDATION_MESSAGE,
} from '@moxie/constants';
import {
  Avatar,
  Card,
  Col,
  Empty,
  Form,
  Row,
  Select,
  Skeleton,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { Editor } from '@shared-components/forms';
import {
  IContactCrm,
  IVisitPurposeCrm,
} from '@shared-components/models';
import { IOfficeVisitForm, } from '@model/crm/office-visit';
import { serviceActions, useAppSelector } from '@crm/core';
import {
  fetchPrevVisit,
  fetchPrevVisitByContact,
  getcontactList,
  getVisitPurposeListBasedOnService,
  queryAssignees,
} from '@crm/services.api';
import { errorHandler } from '@moxie/utils';
import { useDispatch } from 'react-redux';
import { ContactProfileCard } from 'libs/shared/src/modules/contacts/libs/contact-profile';
import moment from 'moment';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import SearchBox from '../../shared/search-box';
import { UserCard } from '../../shared';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';
import { RootState } from '@crm/src/core/store';

const antIcon = <LoadingOutlined className="font-large" spin />;

const OfficeVisitForm: React.FC<IOfficeVisitForm> = ({
  form,
  handleFieldsChange,
  initialData,
  submitForm,
}: IOfficeVisitForm) => {
  const {
    dataLoading,
    services,
  } = useAppSelector(
    (state) => ({
      dataLoading: state.office_visits.loading,
      services: state.services.allData,
    })
  );
  const currentUser = useAppSelector((store: RootState) => store.auth.user);
  const [visitPurposes, setVisitPurposes] = useState<IVisitPurposeCrm[]>([]);
  const [loading, setLoading] = useState({
    visitPurpose: true,
    contact: false,
  });
  const [prevVisits, setPrevVisits] = useState([]);
  const [prevVisitsLoading, setPrevVisitsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getVisitPurposes = async (serviceId: string) => {
    try {
      if (serviceId) {
        const res = await getVisitPurposeListBasedOnService(serviceId);
        setVisitPurposes(res?.data ?? []);
      }
    } catch (e) {
      errorHandler(e);
      setVisitPurposes([]);
    } finally {
      setLoading({ ...loading, visitPurpose: false });
      form.setFieldsValue({ visitPurposeId: '' });
    }
  };

  const getPreviousVisits = async (id?: string) => {
    if (id) {
      setPrevVisitsLoading(true);
      const res = await fetchPrevVisitByContact(id, { limit: 3 });
      setPrevVisits(res.data.data);
    }
    setPrevVisitsLoading(false);
  };

  useEffect(() => {
    const setVisitPurposeList = async (id: string) => {
      const res = await getVisitPurposeListBasedOnService(id);
      setVisitPurposes(res?.data ?? []);
      setLoading({ ...loading, visitPurpose: false });
    };

    const getPrevVisits = async (id: string) => {
      setPrevVisitsLoading(true);
      const res = await fetchPrevVisit(id);
      setPrevVisits(res?.data?.data);
      setPrevVisitsLoading(false);
    };

    if (initialData?.id) {
      setLoading({ contact: true, visitPurpose: true });
      setVisitPurposeList(initialData.serviceId!);
      getPrevVisits(initialData.id);
      form.setFieldsValue(initialData);
    }
  }, [initialData?.id]);

  useEffect(() => {
    dispatch(serviceActions.getServicesRequest());
  }, []);

  return dataLoading || (loading.contact && loading.visitPurpose) ? (
    <Skeleton active />
  ) : (
    <Form
      layout="vertical"
      size={FORM_SIZE}
      form={form}
      onFieldsChange={handleFieldsChange}
      onFinish={submitForm}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="contactId"
            label={LABEL.CONTACT}
            rules={[
              { required: true, message: VALIDATION_MESSAGE.SELECT_CONTACT },
            ]}
            data-testid="crm-officevisit-contact"
          >
            <SearchBox
              filter={{
                type: 'globalSearch',
                sortBy: 'internalId:ASC',
              }}
              placeholder="Select Contact"
              defaultOpen={false}
              showArrow
              fetchQuery={getcontactList}
              hideDefaultOptionView
              optionLabelProp='label'
              onChange={getPreviousVisits}
              disabled={!!initialData?.id}
              className="clear-disable"
            >
              {
                (option: IContactCrm) => {
                  const { id, company, firstName, lastName, email, phone, status, branch, archived, internalId } = option;
                  const fullName = `${firstName} ${lastName}`;
                  return (
                    <Select.Option
                      className="contact-selected-background"
                      value={id}
                      key={id}
                      label={
                        <>
                          <label className="text-primary-color initial_capital">
                            {fullName}
                          </label>
                          <label className="profile__title margin-left-1 ">
                            |
                          </label>
                          <label className="contact-profile-text margin-left-1">
                            {company?.companyCode ? `${company?.companyCode}-` : null}{internalId}
                          </label>
                        </>
                      }
                    >
                      <ContactProfileCard
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        phone={phone!}
                        status={status!}
                        branchName={branch!.name}
                        archivedContact={archived}
                        id={`${internalId}`}
                      />
                    </Select.Option>
                  )
                }
              }

            </SearchBox>
          </Form.Item>
        </Col>
      </Row>
      {form.getFieldValue('contactId') && (
        <Row gutter={GUTTER}>
          <Col span={24} className="margin-bottom-2">
            <p className="ant-typography margin-bottom-1">Previous Visits</p>
            <Card size="small" className="prev-visit-card">
              {prevVisitsLoading ? (
                <div className="prev-visit-loader align-items-center">
                  <Spin indicator={antIcon} />
                </div>
              ) : !prevVisitsLoading && prevVisits.length !== 0 ? (
                <>
                  <Row>
                    <Col span={12}>
                      <h3 className="font-weight-normal">Assignee</h3>
                    </Col>
                    <Col span={9}>
                      <h3 className="font-weight-normal">Visit Purpose</h3>
                    </Col>
                    <Col span={3}>
                      <h3 className="font-weight-normal">Date</h3>
                    </Col>
                  </Row>
                  {prevVisits.map((item: any) => {
                    const fullName =
                      item?.assignee?.firstName +
                      ' ' +
                      item?.assignee?.lastName;
                    return (
                      <Row className="margin-bottom-2" key={item?.id}>
                        <Col className="display-flex" span={12}>
                          {item?.assignee ? (
                            <div className="profile__info__item profile__info__user">
                              <Avatar
                                size="default"
                                className="initial_capital margin-right-1"
                              >
                                {item?.assignee?.firstName?.substring(0, 1)}
                                {item?.assignee?.lastName?.substring(0, 1)}
                              </Avatar>
                              <div className="profile__info__user__detail initial_capital">
                                <Typography.Title
                                  level={3}
                                  className="name-fixed-width"
                                  ellipsis={
                                    fullName.length > 30
                                      ? { tooltip: fullName }
                                      : false
                                  }
                                >
                                  {fullName}
                                </Typography.Title>
                                <Tag>{item?.assignee?.branch?.name}</Tag>
                              </div>
                            </div>
                          ) : (
                            <div className="profile__info__item profile__info__user">
                              <Avatar
                                size="default"
                                icon={<UserOutlined />}
                                className="initial_capital margin-right-1"
                                alt="assignee"
                              />
                              <div className="profile__info__user__detail initial_capital">
                                <Typography.Title
                                  level={3}
                                  className="name-fixed-width"
                                >
                                  Unassigned
                                </Typography.Title>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col span={9}>
                          <Typography.Text className="text-primary">
                            {item?.service?.name}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {item?.visitPurpose?.visitPurpose}
                          </Typography.Text>
                        </Col>
                        <Col span={3}>
                          <Typography.Text type="secondary">
                            {moment(item?.created_at).format('DD/MM/yyyy')}
                          </Typography.Text>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <div className="prev-visit-loader align-items-center">
                  <Empty
                    description="No previous visit found"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item
            name="serviceId"
            label={LABEL.SERVICE}
            rules={[{ required: true }]}
            data-testid="crm-officevisit-service"
          >
            <AntSelect
              showSearch
              allowClear
              placeholder={LABEL.SERVICE}
              optionFilterProp="search_prop"
              onChange={getVisitPurposes}
              disabled={!!initialData?.id}
              className="clear-disable"
            >
              {services.length > 0 &&
                services?.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={item?.id}
                      search_prop={item?.name}
                    >
                      {item?.name}
                    </Select.Option>
                  );
                })}
            </AntSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="visitPurposeId"
            label={LABEL.VISIT_PURPOSE}
            rules={[{ required: true }]}
            data-testid="crm-officevisit-visitpurpose"
          >
            <AntSelect
              showSearch
              allowClear
              placeholder={LABEL.VISIT_PURPOSE}
              disabled={!form.getFieldValue('serviceId') || !!initialData?.id}
              optionFilterProp="search_prop"
              className={initialData?.id ? 'clear-disable' : ''}
            >
              {loading.visitPurpose ? (
                <Select.Option value="loading" disabled>
                  <Spin size="small" className="full-width" />
                </Select.Option>
              ) : (
                visitPurposes.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={item?.id}
                      search_prop={item?.visitPurpose}
                    >
                      {item?.visitPurpose}
                    </Select.Option>
                  );
                })
              )}
            </AntSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="assignee_list">
            <Form.Item
              name="assigneeId"
              label={LABEL.ASSIGNEE}
              data-testid="crm-officevisit-assignee"
            >
              <SearchBox
                showArrow
                hideDefaultOptionView
                fetchQuery={queryAssignees}
                filter={{
                  branches: [currentUser?.branchId]
                }}
                optionLabelProp='label'
                allowClear
                defaultOpen={false}
              >
                {
                  (option) => {
                    const { id, firstName, lastName, email } = option;
                    return (
                      <Select.Option
                        value={id}
                        label={`${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`}
                      >
                        <UserCard
                          firstName={firstName}
                          lastName={lastName}
                          email={email}
                          index={id}
                        />
                      </Select.Option>
                    )
                  }
                }

              </SearchBox>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="sessionNotes"
            label={LABEL.SESSION_NOTES}
            data-testid="crm-officevisit-sessionnotes"
          >
            <Editor placeholder={ADD_MESSAGE} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { OfficeVisitForm };

