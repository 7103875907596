import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from '../pages/login/login';
import {
  URL_DASHBOARD,
  URL_LOGIN,
  URL_APPLICATION,
  URL_APPLICATION_DETAIL,
  URL_INSTITUTION,
  URL_PRODUCTS,
  URL_WORKFLOW_TYPES,
  URL_WORKFLOWS,
  URL_INSTITUTE_DETAIL,
  URL_PRODUCT_DETAIL,
  URL_WORKFLOW_VIEW,
  URL_USER,
  URL_USER_DETAIL,
  TENANTS,
  TENANT_DETAIL_LINK,
  URL_CLIENT,
  APPLICATION_SETTING,
  URL_VERIFY_ACCOUNT,
  URL_VERIFY_ACCOUNT_SUCCESS,
  URL_FORGET_PASSWORD,
  URL_CHECK_MAIL,
  URL_AUTH,
  URL_APPLICATION_REPORT,
  URL_CONTACT_REPORT,
} from '@moxie/constants';
import { socketActions, SocketClient, useAppSelector } from '@admin/core';
import { ProductDetail } from './product/product-detail';
import { AdminLogProtectedRoute, ScrollToTop } from '@moxie/shared';
import { ApplicationDetail, ApplicationList } from './application';
import { AdminProtectedRoute } from '@moxie/shared';
import { ProductList } from './product';
import { WorkflowDetail, WorkflowList, WorkflowTypeList } from './workflow';
import { useDispatch } from 'react-redux';
import { userActions } from '@admin/core';
import Auth from './auth';
import { io } from 'socket.io-client';
import DashboardRoute from './dashboard';
const LazyInstitution = React.lazy(() => import('./institution'));
const LazyAdminUser = React.lazy(() => import('./user'));
const LazyAdminUserDetail = React.lazy(
  () => import('./user/UserDetail/UserDetail')
);
const LazyInstitutionDetail = React.lazy(
  () => import('./institution/libs/institution-detail')
);
const LazyAdminTenantList = React.lazy(() => import('./tenants/tenant-list'));
const LazyTenantDetail = React.lazy(() => import('./tenants/tenant-detail'));
const LazyAdminClientList = React.lazy(() => import('./clients/client-list'));
const LazyApplicationSetting = React.lazy(
  () => import('./application-setting')
);
const ContactDetail = React.lazy(() => import('./contact/contact-detail'));
const LazyVerifyAdminAccount = React.lazy(
  async () => await import('./auth/verify-admin-account')
);
const LazyVerifyAdminAccountSuccess = React.lazy(
  async () => await import('./auth/verify-admin-account-success')
);
const LazyForgetPassword = React.lazy(
  async () => await import('./auth/forget-password')
);

const LazyContactReport = React.lazy(
  async () => await import('./report/contact')
);
const LazyApplicationReport = React.lazy(
  async () => await import('./report/application')
);
const LazyCheckMail = React.lazy(async () => await import('./auth/check-mail'));

const App: React.FC = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const authUser = useAppSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(`${process.env.NX_API_URL}`, {
      transports: ['websocket'],
    });
    dispatch(
      socketActions.setSocketRequest({
        payload: socket,
      })
    );

    if (authUser) {
      socket?.emit('new-user-to-server', authUser);
    }
    return () => {
      dispatch(socketActions.removeSocketRequest());
      socket.close();
    };
  }, [dispatch, authUser]);

  useEffect(() => {
    if (authUser) {
      dispatch(userActions.fetchUsers());
    }
  }, [dispatch, authUser]);
  useEffect(() => {
    if (isAuthenticated) {
      // eslint-disable-next-line no-restricted-globals,prefer-const
      let width = screen.width;
      if (width < 1920 && window.location.pathname !== '/') {
        document.body.classList.add('admin-body-zoom');
      } else {
        document.body.classList.remove('admin-body-zoom');
      }
    }
  }, [isAuthenticated]);
  return (
    <Router>
      {isAuthenticated && <SocketClient />}
      <ScrollToTop />
      <Switch>
        <AdminLogProtectedRoute exact path={URL_LOGIN} component={Login} />
        <AdminLogProtectedRoute exact path={URL_AUTH} component={Auth} />

        <AdminProtectedRoute
          exact
          path={URL_APPLICATION}
          component={ApplicationList}
        />
        <AdminProtectedRoute
          exact
          path={URL_APPLICATION_DETAIL}
          component={ApplicationDetail}
        />
        <AdminProtectedRoute
          exact
          path={URL_PRODUCTS}
          component={ProductList}
        />
        <AdminProtectedRoute
          exact
          path={URL_PRODUCT_DETAIL}
          component={ProductDetail}
        />
        <AdminProtectedRoute
          exact
          path={URL_WORKFLOWS}
          component={WorkflowList}
        />
        <AdminProtectedRoute
          exact
          path={URL_WORKFLOW_TYPES}
          component={WorkflowTypeList}
        />

        <AdminProtectedRoute
          exact
          path={URL_WORKFLOW_VIEW}
          component={WorkflowDetail}
        />

        <AdminProtectedRoute path={URL_DASHBOARD} component={DashboardRoute} />

        <AdminProtectedRoute
          exact
          path={URL_INSTITUTION}
          component={LazyInstitution}
        />

        <AdminProtectedRoute
          exact
          path={URL_INSTITUTE_DETAIL}
          component={LazyInstitutionDetail}
        />

        <AdminProtectedRoute exact path={URL_USER} component={LazyAdminUser} />
        <AdminProtectedRoute
          exact
          path={URL_USER_DETAIL}
          component={LazyAdminUserDetail}
        />
        <AdminProtectedRoute
          exact
          path="/contact/detail/:id/:slug"
          component={ContactDetail}
        />
        <AdminProtectedRoute
          exact
          path="/contact/detail/:id/:slug/:applicationId"
          component={ContactDetail}
        />
        <AdminProtectedRoute
          exact
          path={TENANTS}
          component={LazyAdminTenantList}
        />
        <AdminProtectedRoute
          exact
          path={URL_CLIENT}
          component={LazyAdminClientList}
        />
        <AdminProtectedRoute
          path={APPLICATION_SETTING}
          component={LazyApplicationSetting}
        />
        <AdminProtectedRoute
          exact
          path={TENANT_DETAIL_LINK}
          component={LazyTenantDetail}
        />
        <AdminLogProtectedRoute
          exact
          path={URL_FORGET_PASSWORD}
          component={LazyForgetPassword}
        />
        <AdminLogProtectedRoute
          exact
          path={URL_CHECK_MAIL}
          component={LazyCheckMail}
        />
        <AdminLogProtectedRoute
          exact
          path={URL_VERIFY_ACCOUNT}
          component={LazyVerifyAdminAccount}
        />
        <AdminLogProtectedRoute
          exact
          path={URL_VERIFY_ACCOUNT_SUCCESS}
          component={LazyVerifyAdminAccountSuccess}
        />
        <AdminProtectedRoute
          exact
          path={URL_CONTACT_REPORT}
          component={LazyContactReport}
        />
        <AdminProtectedRoute
          exact
          path={URL_APPLICATION_REPORT}
          component={LazyApplicationReport}
        />
      </Switch>
    </Router>
  );
};

export default App;
