import { crmAxios } from "@crm/services.api";
import { API_URL } from "@moxie/constants";

interface PaginationPayload {
    page?:number;
    limit?: number;
    filter?: Record<string,unknown>
}
export const fetchContactEnquiries = async (query: PaginationPayload) => {
const val = await crmAxios.get(API_URL.CRM_API_CONTACT_ENQUIRIES,{
    params: query
})
return val.data
}
