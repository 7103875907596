export const URL_LOGIN = '/';
export const URL_LOGIN_ADMIN = '/';
export const URL_CHECK_MAIL = '/check-mail';
export const URL_DASHBOARD = '/dashboard';
export const URL_CONTACTS = '/contacts/:name';
export const URL_PROSPECTS = '/prospects/:name';
export const URL_MY_PROSPECTS = '/prospects/my-prospects';
export const URL_ALL_PROSPECTS = '/prospects/all-prospects';
export const URL_CLIENTS = '/clients/:name';
export const URL_MY_CLIENTS = '/clients/my-clients';
export const URL_ALL_CLIENTS = '/clients/all-clients';
export const DASHBOARD_ROUTES = {
  profile: {
    path: '/dashboard/profile',
    name: ' My Profile',
  },
  accountSettings: {
    path: '/dashboard/account-settings',
    name: 'Account Settings',
  },
  changePassword: {
    path: '/dashboard/account-settings',
    name: 'Change Password',
  },
  settings: {
    path: '/dashboard/settings',
    name: 'Organization Settings',
  },
  preferences: {
    path: '/dashboard/settings',
    name: 'Preferences',
  },
  roles: {
    path: '/dashboard/settings/roles',
    name: 'Preferences',
  },
  otherDetails: {
    path: '/dashboard/settings/other-details',
    name: 'Other Details',
  },
  logout: {
    name: 'Logout',
  },
};
export const APPLICATION_SETTING_ROUTES = {
  otherDetails: {
    path: '/application-settings/other-details',
    name: 'Form Fields',
  },
  documentChecklist: {
    path: '/application-settings/document-checklist',
    name: 'Document Checklist',
  },
};
export const URL_FORGET_PASSWORD = '/forget-password';
export const URL_CRM_FORGET_PASSWORD = '/crm/forget-password';
export const URL_AUTH = '/auth/confirm';
export const URL_CRM_AUTH = '/auth/crm/confirm';
export const URL_LEAD = '/leads/:name';
export const URL_MY_LEAD = '/leads/my';
export const URL_ALL_LEAD = '/leads/all';
export const URL_ALL_CONTACTS = '/contacts/all-contacts';
export const URL_MY_CONTACTS = '/contacts/my-contacts';
export const URL_CONTACT_DETAIL = '/contact/detail/';
export const URL_INSTITUTE_DETAIL = '/institution/detail/:id/:slug';
export const URL_INSTITUTE = '/institution';
export const URL_INSTITUTE_IMPORT = '/institution/import';

export const URL_NOTIFICATION = '/notification/all';
export const URL_INSTITUTE_ADMIN = '/institution/all';
export const URL_PRODUCTS = '/products';
export const URL_WORKFLOWS = '/workflows';
export const URL_CRM_WORKFLOWS = '/service/:serviceId/workflows';
export const URL_CRM_WORKFLOW_DETAIL = '/workflow/view/:id';
export const URL_WORKFLOW_VIEW = '/workflows/view/:id';
export const URL_CRM_WORKFLOW_VIEW = '/workflow/view';
export const URL_WORKFLOW_TYPES = '/workflow-type';
export const URL_CRM_WORKFLOW_TYPES = '/service/:serviceId/workflow-type';
export const URL_AGENTS = '/agents/:type';
export const URL_SUB_AGENTS = '/agents/sub_agent';
export const URL_VERIFICATION = '/auth/verify';
export const URL_USER = '/users/:name';
export const URL_USER_ACTIVE = '/users/active';
export const URL_SERVICE = '/services';
export const URL_USER_ADD = '/users/add';
export const URL_REGISTER = '/auth/register/';
export const URL_AUTH_CHECK_ACCOUNT = '/auth/check/account';
export const URL_CHECK_ACCOUNT = '/lead/check/account';
export const URL_OFFICE = '/offices';
export const URL_OFFICE_BRANCH_DETAIL = '/offices/detail/:id/:slug';
export const URL_LEAD_FORM = '/lead-forms/:name';
export const URL_ACTIVE_LEAD_FORM = '/lead-forms/active';
export const URL_DETAIL_LINK = '/contact/detail/';
export const URL_INSTITUTION_DETAIL_LINK = '/institution/detail/';
export const TENANT_DETAIL_LINK = '/tenant/detail/:id';
export const URL_TENANT_DETAIL_LINK = '/tenant/detail/';
export const URL_PRODUCT_DETAIL_LINK = '/products/detail/';
export const URL_USER_DETAIL_LINK = '/users/detail/';
export const URL_PRODUCT_DETAIL = '/products/detail/:id/:slug';
export const URL_USER_DETAIL = '/users/detail/:id';
export const TENANTS = '/tenants/all';
export const URL_CLIENT = '/clients';
export const APPLICATION_SETTING = '/application-settings';
export const URL_CREATED_LEAD_FORM = '/online-form';
export const URL_LEAD_DETAIL = '/contact/detail/:id/:slug/:slug2?';
export const URL_APPOINTMENT = '/appointments/all';
export const URL_TASKS = '/tasks/:name';
export const URL_ALL_TASKS = '/tasks/all';
export const UPLOADS = './uploads';
export const LEAD_DOCUMENTS = 'documents';
export const LEAD_NOTE = 'note';
export const URL_LEAD_DOCUMENTS = 'contact-document/documents';
export const URL_PRODUCT_DOCUMENTS = 'products/documents';
export const URL_LEAD_DOCUMENT = 'contact-document/document/';
export const URL_DOCUMENT_DELETE = 'documents/';
export const URL_VERIFY_ACCOUNT = '/auth/verify/account';
export const URL_CRM_VERIFY_ACCOUNT = '/auth/crm/verify/account';
export const URL_VERIFY_ACCOUNT_SUCCESS = '/auth/verify/account/success';
export const URL_CRM_VERIFY_ACCOUNT_SUCCESS =
  '/auth/crm/verify/account/success';
export const URL_DEPARTMENT = '/department';
export const URL_DEPARTMENT_DETAIL = '/department/detail/:id';
export const URL_LEAD_PRODUCT_COMPARE = '/lead/product-compare/:id';
export const URL_APPLICATION = '/application';
export const URL_APPLICATION_DETAIL = '/application/detail/:applicationId';
export const URL_INSTITUTION = '/institution/all';
export const URL_LEAD_REPORT = '/report/contact';
export const URL_CONTACT_REPORT = '/report/contact';
export const URL_APPLICATION_REPORT = '/report/application';
export const URL_FORBIDDEN_ACCESS = '/forbidden-access';
export const URL_PUBLIC_CONTACT_FORM = '/online-form';
export const API_ENDPOINT_URL = {
  BRANCHES: 'branches',
  DEPARTMENT: 'departments',
  APPLICATIONS: 'applications',
  APPLICATION_STAGES: 'application-stages',
  WORKFLOW_APPLICATION_STAGES: 'workflow-application-stage',
  WORKFLOW_FORM: 'workflow-form',
  SERVICES: 'services',
  WORKFLOW: 'workflow',
  WORKFLOW_TYPE: 'workflow-type',
  CHECKLISTS: 'checklists',
  TASKS: 'tasks',
};

export const AM_API_URL = {
  CHECK_USER_EMAIL: '/am/user/check-email',
  GET_CURRENT_USER: '/am/user',
  LOGOUT: '/am/auth/logout',
  ADD_USER: '/am/user/add',
  USER_LIST: 'am/user/list',
  USER: '/am/user',
  INSTITUTION_LIST: '/institutions/am/list',
  CHANGE_PASSWORD: 'am/auth/change-password',
  FORGETPASSWORD: '/am/auth/forgot-password',
  RESETPASSWORD: '/am/auth/reset-password',
};

export const API_URL = {
  LOGIN: '/auth/login/',
  ADMIN_LOGIN: '/auth/admin/login/',
  FORGETPASSWORD: '/auth/forgetpassword',
  CHANGEPASSWORD: '/auth/changePassword',
  CHANGE_CRM_ACCOUNT_PASSWORD: '/auth/change-password',
  ADMIN_FORGET_PASSWORD: '/auth/admin/forget-password',
  CRM_FORGET_PASSWORD: '/auth/forget-password',
  EMAIL_VERIFICATION: '/auth/user/verify',
  ADMIN_EMAIL_VERIFICATION: '/auth/admin/user/verify',
  RESEND_EMAIL_VERIFICATION: 'auth/user/verify/link',
  RESEND_ADMIN_EMAIL_VERIFICATION: 'auth/admin/user/verify/link',
  RESET_PASSWORD: '/auth/reset-password',
  ADMIN_AUTH: '/auth/admin/reset-password',
  REFRESH_TOKEN: '/am/auth/refresh/token/',
  CRM_API_REFRESH_TOKEN: 'auth/refresh/token',
  ADMIN_REFRESH_TOKEN: '/auth/admin/refresh/token/',
  GET_CURRENT_USER: '/auth/user',
  GET_CURRENT_ADMIN_USER: '/auth/admin/user',
  LOGOUT: '/auth/logout',
  ADMIN_LOGOUT: '/auth/admin/logout',
  LEAD: '/contact',
  DOWNLOAD_TEMPLATE: '/contact/file/download',
  UPLOAD_FILE: '/contact/upload-file',
  UPLOAD_IMAGE: '/contact/upload-avatar',
  IMPORT_DATA: '/contact/import-data',
  LEADS_FORM: '/contacts-form',
  OFFICE_BRANCH: '/branches',
  CRM_OFFICE: 'crm/branches',
  CRM_API_OFFICE: 'v1/branches',
  CRM_USER: 'crm/users',
  CRM_API_USER: 'v1/users',
  CRM_USER_ACTIVE: 'crm/users/active',
  CRM_ROLE: 'crm/roles',
  CRM_INSTITUTION: 'crm/institutions',
  CRM_API_INSTITUTION_TYPES: 'v1/institution-types',
  CRM_API_INSTITUTIONS: 'v1/institutions',
  DEPARTMENTS: '/departments',
  APPLICATIONS: '/applications',
  ADMIN_APPLICATIONS: '/applications/admin',
  ADMIN_APPLICATIONS_FILTER_TENANT: '/applications/admin/filter-tenant-data',
  WORKFLOWS: '/workflows',
  CRM_WORKFLOW: '/crm/workflow',
  CRM_API_WORKFLOW: 'v1/workflows',
  WORKFLOW_TYPE: '/workflow-type',
  CRM_WORKFLOW_TYPE: '/crm/workflow-type',
  CRM_API_WORKFLOW_TYPE: 'v1/workflow-types',
  CRM_API_WORKFLOW_APPLICATION_STAGES: 'v1/workflow-application-stages',
  CRM_API_STAGE_CHECKLISTS: 'v1/stage-checklists',
  CRM_API_CHECKLISTS: 'v1/checklists',
  SERVICES: '/services',
  CHECKLIST_DOCUMENTS: '/documents/workflow-application-stage',
  ADMIN_CONTACT_DOCUMENT: '/contact-document/admin/documents',
  ADMIN_CHECKLIST_DOCUMENTS: '/documents/admin/workflow-application-stage',
  WORKFLOW_CHECKLIST_DOCUMENTS: '/workflow-application-stage/document',
  EDUCATION_BACKGROUND: '/education-backgrounds',
  EDUCATION_BACKGROUND_LEVELS: '/education-backgrounds/levels',
  URL_CRM_EDUCATION_BACKGROUNDS: 'crm/education-backgrounds',
  ADD_CONTACT_EDUCATION_BACKGROUNDS: 'crm/education-backgrounds/contact/add',
  CRM_API_EDUCATION_BACKGROUNDS: 'v1/education-backgrounds',
  CONTACT_EDUCATION_BACKGROUNDS: 'crm/education-backgrounds/contact',
  LANGUAGE_TEST_SCORE: '/contact-language-test-score',
  OTHER_TEST_SCORE: '/contact-other-test-scores',
  DOCUMENTS: '/documents',
  CONTACT_DOCUMENTS: '/documents/contact',
  UPLOAD_TASK_DOCUMENT: 'task-document/upload',
  HARD_DELETE_DOCUMENT: '/documents/hard-delete',
  LEAD_DOCUMENT_SAVE: 'contact-document/save',
  LEAD_APPOINTMENT_SAVE: 'contact-appointment/save',
  LEAD_GET_APPOINTMENTS: 'contact-appointment/all/',
  LEAD_GET_MY_APPOINTMENTS: 'contact-appointment/my/appointments',
  LEAD_DELETE_APPOINTMENTS: 'contact-appointment/',
  LEAD_UPDATE_APPOINTMENTS: 'contact-appointment/update',
  LEAD_APPOINTMENTS_STATUS: 'contact-appointment/status',

  INSTITUTIONS: '/institutions',
  INSTITUTIONS_LIST: '/institutions/list',
  PRODUCTS: '/products',
  PRODUCT_TYPES: '/products/types',
  CRM_PRODUCT_TYPE_BY_SERVICE_ID: '/crm/products/productTypes/byServiceId',
  CRM_PRODUCT_TYPE_WITH_SUB_TYPE: 'crm/products/product-types',
  CRM_PRODUCT_SUB_TYPES: '/crm/products/productSubTypes',
  PRODUCT_TYPES_FOR_AM: '/products/am/types',
  SUBJECT_DISCIPLINES: '/subject-disciplines',
  CRM_SUBJECT_DISCIPLINES: 'crm/subject-disciplines',
  CRM_API_SUBJECT_DISCIPLINES: 'v1/subject-disciplines',
  PRODUCTS_FEES: '/product-fees',

  CRM_PRODUCTS: 'crm/products',
  CRM_API_PRODUCTS: 'v1/products',
  CRM_PRODUCT_LANGUAGE_TEST_SCORE: 'v1/product-language-test-score',

  ROLE: '/roles',
  ADMIN_ROLE: '/admin_roles',
  ADMIN_PERMISSIONS: '/admin_permissions',
  PERMISSIONS: '/permissions',
  USER: '/user',
  USER_ADD: '/user/add',
  USER_LIST: '/user/list',
  ADMIN_USER: '/admin_users',
  ADMIN_USER_ADD: '/admin_users/add',
  ADMIN_USER_LIST: '/admin_users/list',
  COMPANY: '/company',
  COMPANY_CRM: '/company/crm',
  CRM_API_COMPANY: 'company',
  CLIENT: '/contact/client/list',

  URL_ADD_LEAD: '/contact',
  URL_CRM_ADD_LEAD: '/crm/contacts',
  URL_DELETE_LEAD: '/contact/delete/many',
  URL_EDIT_LEAD: '/contact/',
  URL_MULTIPLE_EDIT_LEAD: '/contact/multiple',
  URL_GENERATE_LEAD: '/contacts-form/generate-contact',

  URL_ADD_NOTE: '/notes',

  PROFESSIONAL_EXPERIENCE: '/contact-professional-experiences',
  CRM_CONTACT_PROFESSIONAL_EXPERIENCE: 'crm/contact-professional-experiences',
  CRM_API_PROFESSIONAL_EXPERIENCE: 'v1/professional-experiences',
  CRM_API_CONTACT_LANG_TEST_SCORE: 'v1/contact-language-test-scores',

  TASKS: '/tasks',
  TASK_DOCUMENT_SAVE: 'task-document/save',
  TASK_DOCUMENT_UPDATE: 'task-document/update',
  TASK_DOCUMENT_DELETE: 'task-document/delete',

  SERVICE: `/services`,

  URL_GET_INTERESTED_SERVICE: '/interested-services/contacts/',
  URL_INTERESTED_SERVICE: '/interested-services',

  URL_OTHER_DETAIL_CATEGORIES: '/contact-other-details/categories',
  URL_OTHER_DETAIL_LABELS: '/other-detail-fields',
  URL_OTHER_DETAIL_VALUES: '/other-detail-value',
  URL_OPTIONS: '/options',
  NOTIFICATION: `/notifications`,
  TENANTS: '/company',

  CRM_SERVICES: `/crm/services`,
  CRM_API_SERVICES: 'v1/services',
  VISIT_PURPOSE: `/visit-purpose`,
  OFFICE_VISIT: `/crm/contact-visit`,
  CRM_API_OFFICE_VISIT: "v1/office-visits",
  CRM_CONTACT: `/crm/contacts`,
  CRM_CONTACT_ACTIVITIES: `/v1/contact-activities`,
  CRM_API_CONTACT: `v1/contacts`,
  VISIT_ACTIVITY_LOG: '/visit-activity-logs',
  CRM_DOCUMENTS: '/crm/documents',

  CRM_AGENT_PARTNER: '/v1/agent-partners',
  CRM_APPLICATIONS: '/crm/applications',

  OAUTH_USER: '/v1/oauth',
  CALENDAR: '/v1/calendar',
  CRM_AGENT: '/crm/agent-partner',
  CRM_API_AGENT: '/v1/agent-partners',
  CRM_API_CONTACT_ENQUIRIES: '/v1/contact-enquiries',
  CRM_PRODUCT_FEE: 'v1/product-fees',
  CRM_NOTIFICATION: '/crm/notifications',
  CRM_API_NOTIFICATION: '/v1/notifications',

  CRM_USER_PERMISSION: 'v1/user-permissions',
  CRM_CONTACTS_REPORTS: 'v1/contacts/generate-reports',
  CRM_API_CONTACT_ENQUIRY: 'v1/contact-enquiries',
  CRM_API_CONTACT_ASSIGNEES: 'v1/contacts/assignees',
  CRM_API_CONTACT_COUNTRIES: 'v1/contacts/countries',
  CRM_API_CONTACT_STATES: 'v1/contacts/states',
  CRM_API_COUNTRY_PASSPORT: 'v1/contacts/passport-countries',
  CRM_MEDIA: 'v1/media',
  CRM_APPLICATION_COMMENTS: '/v1/application-comments',
  CRM_API_APPLICATION: '/v1/applications',
  VALIDATE_DOMAIN: 'v1/company'
};
export const URL_OFFICE_VISIT_WAITING = '/office-visit/waiting';
export const URL_OFFICE_VISIT = '/office-visit/:name';
export const URL_CALENDAR = '/calendar';
export const URL_ENQUIRIES = '/enquiries/:type';
export const URL_ENQUIRIES_NEW_CONTACTS = 'enquiries/new-contacts';
export const URL_ENQUIRIES_FORM = '/enquiry-forms';
