import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  PROFILE_EDIT_TITLE,
  UPDATE_BTN,
  USER_ADD_PLACEMENT,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space } from 'antd';
import React, { useState } from 'react';
import UserProfileForm from './user-form';
export const UserDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  userProfileForm,
}: any) => {
  const [disabled, setDisabled] = useState(true);
  return (
    <DrawerElem
      title={PROFILE_EDIT_TITLE}
      width={DRAWER_WIDTH}
      visible={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      placement={USER_ADD_PLACEMENT}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={() => setIsDrawerOpen(false)}
              className="lead-margin-right"
            >
              {CANCEL_BTN}
            </Button>

            <Button
              type="primary"
              onClick={() => {
                userProfileForm.submit();
              }}
              disabled={disabled}
            >
              {UPDATE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <UserProfileForm
        setDisabled={setDisabled}
        userProfileForm={userProfileForm}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </DrawerElem>
  );
};
